import type { User } from "@sferadel/ts-lib/entities"
import type { ComponentProps } from "solid-js"
import { createEffect, createMemo, createSignal, Match, Show, splitProps, Switch } from "solid-js"
import { type ComponentLike, createRwSignal, getPictureUrl } from "#/lib/utils"
import { combineProps } from "#/lib/combine-props"
import { NOOP } from "@sferadel/ts-lib"
import { useCache } from "#/contexts"
import { OnlineStatus } from "#/proto/schema"
import { IMG_REMOVE_BORDER_SRC } from "./picture"

export let stringToColor = (text: string, lightness = 55, saturation = 76) =>
	window.crypto.subtle?.digest("SHA-1", new TextEncoder().encode(text))
		.then(x => new Uint8Array(x))
		.then(x => x.join("").slice(16))
		.then(Number)
		.then(hash => `hsl(${hash % 360},${saturation}%,${lightness}%)`)

function NamedAvatar(props: { name: string } & ComponentProps<"div">) {
	let [first_name, surname] = props.name.split(" ")
	let bg = createRwSignal(null)

	props = combineProps({
		class: "flex items-center justify-center font-bold c-white",
		style: { "container-type": "inline-size" },
	}, props)
	let [, other] = splitProps(props, ["name"])

	Promise
		.all([first_name, surname].map(n => stringToColor(n)))
		.then(([c1, c2]) => `linear-gradient(150deg, ${c1},${c2})`)
		.then(bg)
		.catch(NOOP)

	return (
		<div {...other} style={{ background: bg(), ...props.style }}>
			<span class="text-50cqw" children={[first_name[0], surname[0]]} />
		</div>
	)
}

type UserAvatarProps = {
	user: Partial<Pick<User, "avatar_key" | "name" | "num">>
	Wrapper?: ComponentLike<"div">
	Img?: ComponentLike<"img">
} & ComponentProps<"div">

export function UserAvatar(props: UserAvatarProps) {
	props = combineProps({
		class: "relative rounded aspect-ratio-square max-w-inherit max-h-inherit",
		Wrapper: p => <div {...p} />,
		Img: p => <img {...p} />,
	}, props)
	let [, other] = splitProps(props, ["user", "Wrapper", "Img"])

	let cache = useCache()

	let [error, setError] = createSignal(false)

	let getOnlineStatus = createMemo(() => cache.resolve("presense_list", props.user?.num)?.status)

	createEffect(() => {
		props.user
		props.user?.name
		props.user?.avatar_key
		setError(false)
	})

	let INNER_CLASS = "hfull wfull object-cover rounded-inherit"

	let Img = (p: ComponentProps<"img">) => (
		<props.Img class={INNER_CLASS} onError={[setError, true]} {...p} />
	)

	return (
		<props.Wrapper {...other}>
			<Switch>
				<Match when={!props.user || !props.user.name && !props.user.avatar_key}>
					<Img src={IMG_REMOVE_BORDER_SRC} />
				</Match>
				<Match when={props.user.name && (!props.user.avatar_key || error())}>
					<NamedAvatar name={props.user.name} class={INNER_CLASS} />
				</Match>
				<Match when={props.user.avatar_key}>
					<Img src={getPictureUrl(props.user.avatar_key)} />
				</Match>
			</Switch>
			<Show when={getOnlineStatus()}>
				<div class="absolute rounded h25% w25% right-3% bottom-3% light:bg-white dark:bg-gray-900">
					<div
						class="h75% w75% rounded abs-centered"
						classList={{
							[
								function() {
									switch (getOnlineStatus()) {
										case OnlineStatus.ONLINE:
											return "bg-green"
										case OnlineStatus.AWAY:
											return "bg-amber i-hero:moon-solid"
									}
								}()
							]: true,
						}}
					/>
				</div>
			</Show>
		</props.Wrapper>
	)
}
