// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.26.1
// source: schema.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";
import { Struct } from "./google/protobuf/struct";

export const protobufPackage = "";

/** pkg-ts-www/contexts/cache.context.tsx */
export enum EntityType {
  USER = 0,
  CHAT = 1,
  MESSAGE = 2,
  DEAL = 3,
  PROJECT = 4,
  PROJECT_COMMENT = 5,
  POST = 6,
  TAG = 7,
  UNRECOGNIZED = -1,
}

export enum EntityUpdateType {
  COMPLETE = 0,
  PARTIAL = 1,
  UNRECOGNIZED = -1,
}

export enum OnlineStatus {
  OFFLINE = 0,
  AWAY = 1,
  ONLINE = 2,
  UNRECOGNIZED = -1,
}

/**
 * Cmd: Client CoMmanD 	-> Server
 * Cb: 	Server CallBack -> Client
 * Ac: 	Server ACtion	-> Client random realtime action
 */
export interface RpcMessage {
  id: number;
  answer_to?: number | undefined;
  variant?:
    | { $case: "ping"; ping: Empty }
    | { $case: "cb_error"; cb_error: CbError }
    | { $case: "ac_batch_update"; ac_batch_update: { [key: string]: any } | undefined }
    | { $case: "ac_entity_updated"; ac_entity_updated: AcEntityUpdated }
    | { $case: "ac_entity_deleted"; ac_entity_deleted: AcEntityDeleted }
    | { $case: "ac_version_info"; ac_version_info: string }
    | { $case: "ac_refresh_presence"; ac_refresh_presence: AcRefreshPresence }
    | { $case: "ac_presence_changed"; ac_presence_changed: AcPresenceChanged }
    | { $case: "cmd_presence_changed"; cmd_presence_changed: OnlineStatus }
    | { $case: "cmd_friendship_request"; cmd_friendship_request: number }
    | { $case: "cmd_set_status"; cmd_set_status: string }
    | { $case: "cmd_send_message"; cmd_send_message: CmdSendMessage }
    | { $case: "cb_added_to_chat"; cb_added_to_chat: CbAddedToChat }
    | { $case: "ac_message_received"; ac_message_received: { [key: string]: any } | undefined }
    | { $case: "cmd_mark_as_read"; cmd_mark_as_read: CmdMarkAsRead }
    | { $case: "cmd_project_vote"; cmd_project_vote: CmdProjectVote }
    | { $case: "ac_project_comment_received"; ac_project_comment_received: { [key: string]: any } | undefined }
    | { $case: "cb_project_vote"; cb_project_vote: CbProjectVote }
    | { $case: "cmd_project_add_comment"; cmd_project_add_comment: CmdProjectAddComment }
    | { $case: "cmd_project_remove_comment"; cmd_project_remove_comment: number }
    | { $case: "cmd_post_new"; cmd_post_new: CmdPostUpsert }
    | { $case: "cmd_post_edit"; cmd_post_edit: CmdPostUpsert }
    | { $case: "cmd_post_react"; cmd_post_react: CmdPostReact }
    | { $case: "cmd_submit_report"; cmd_submit_report: CmdSubmitReport }
    | undefined;
}

/** Basic */
export interface CbError {
  description: string;
}

/** created or updated */
export interface AcEntityUpdated {
  entity_type: EntityType;
  num: number;
  entity: { [key: string]: any } | undefined;
  update_type: EntityUpdateType;
}

export interface AcEntityDeleted {
  entity_type: EntityType;
  num: number;
}

export interface CmdProjectAddComment {
  project_num: number;
  reply_to?: number | undefined;
  text?: string | undefined;
  mentioned_nicknames: string[];
  attachments: MessageAttachment[];
}

export interface CmdProjectVote {
  project_num: number;
  upvote: boolean;
  comment?: string | undefined;
}

export interface CbProjectVote {
  variant?:
    | { $case: "cancelled_vote"; cancelled_vote: number }
    | { $case: "updated_vote"; updated_vote: { [key: string]: any } | undefined }
    | { $case: "created_vote"; created_vote: { [key: string]: any } | undefined }
    | { $case: "error_pool_empty"; error_pool_empty: number }
    | { $case: "error_no_comment"; error_no_comment: Empty }
    | undefined;
}

/** Chat iteraction */
export interface CbAddedToChat {
  chat_num: number;
}

export interface CmdSendMessage {
  to_nickname: string;
  text?: string | undefined;
  attachments: MessageAttachment[];
}

export interface MessageAttachment {
  attachment?:
    | { $case: "voice"; voice: VoiceAttachment }
    | { $case: "picture"; picture: PictureAttachment }
    | undefined;
}

export interface PictureAttachment {
  data: Uint8Array;
  mime_type: string;
}

export interface VoiceAttachment {
  data: Uint8Array;
  duration_ms: number;
  mime_type: string;
  waveform: Uint8Array;
}

export interface CmdProjectUpsert {
  num?: number | undefined;
  name?: string | undefined;
  description?: string | undefined;
  website?: string | undefined;
  sphere?: string | undefined;
  tags: { [key: string]: any }[];
  avatar?: ImageEditDescriptor | undefined;
  background?: ImageEditDescriptor | undefined;
  gallery: ImageEditDescriptor[];
}

export interface ImageEditDescriptor {
  type?: { $case: "replacement"; replacement: Uint8Array } | { $case: "key"; key: string } | undefined;
}

export interface CmdPostUpsert {
  text?: string | undefined;
  tag_nums: number[];
  images: Uint8Array[];
}

export interface CmdPostReact {
  post_num: number;
  emoji: string;
}

export interface CmdMarkAsRead {
  chat_num: number;
  last_read_message_num: number;
}

export interface AcProjectScoreChanged {
  project_num: number;
  score: number;
}

export interface ProjectVote {
  project_num: number;
  bet: number;
}

export interface AcRefreshPresence {
  map: { [key: number]: OnlineStatus };
}

export interface AcRefreshPresence_MapEntry {
  key: number;
  value: OnlineStatus;
}

export interface AcPresenceChanged {
  user_num: number;
  status: OnlineStatus;
  last_seen_at?: number | undefined;
}

export interface CmdSubmitReport {
  entity_type: EntityType;
  entity_num: number;
  reason: string;
}

function createBaseRpcMessage(): RpcMessage {
  return { id: 0, answer_to: undefined, variant: undefined };
}

export const RpcMessage = {
  encode(message: RpcMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.answer_to !== undefined) {
      writer.uint32(16).uint32(message.answer_to);
    }
    switch (message.variant?.$case) {
      case "ping":
        Empty.encode(message.variant.ping, writer.uint32(26).fork()).ldelim();
        break;
      case "cb_error":
        CbError.encode(message.variant.cb_error, writer.uint32(34).fork()).ldelim();
        break;
      case "ac_batch_update":
        Struct.encode(Struct.wrap(message.variant.ac_batch_update), writer.uint32(42).fork()).ldelim();
        break;
      case "ac_entity_updated":
        AcEntityUpdated.encode(message.variant.ac_entity_updated, writer.uint32(50).fork()).ldelim();
        break;
      case "ac_entity_deleted":
        AcEntityDeleted.encode(message.variant.ac_entity_deleted, writer.uint32(58).fork()).ldelim();
        break;
      case "ac_version_info":
        writer.uint32(66).string(message.variant.ac_version_info);
        break;
      case "ac_refresh_presence":
        AcRefreshPresence.encode(message.variant.ac_refresh_presence, writer.uint32(74).fork()).ldelim();
        break;
      case "ac_presence_changed":
        AcPresenceChanged.encode(message.variant.ac_presence_changed, writer.uint32(82).fork()).ldelim();
        break;
      case "cmd_presence_changed":
        writer.uint32(88).int32(message.variant.cmd_presence_changed);
        break;
      case "cmd_friendship_request":
        writer.uint32(96).int64(message.variant.cmd_friendship_request);
        break;
      case "cmd_set_status":
        writer.uint32(106).string(message.variant.cmd_set_status);
        break;
      case "cmd_send_message":
        CmdSendMessage.encode(message.variant.cmd_send_message, writer.uint32(114).fork()).ldelim();
        break;
      case "cb_added_to_chat":
        CbAddedToChat.encode(message.variant.cb_added_to_chat, writer.uint32(122).fork()).ldelim();
        break;
      case "ac_message_received":
        Struct.encode(Struct.wrap(message.variant.ac_message_received), writer.uint32(130).fork()).ldelim();
        break;
      case "cmd_mark_as_read":
        CmdMarkAsRead.encode(message.variant.cmd_mark_as_read, writer.uint32(138).fork()).ldelim();
        break;
      case "cmd_project_vote":
        CmdProjectVote.encode(message.variant.cmd_project_vote, writer.uint32(146).fork()).ldelim();
        break;
      case "ac_project_comment_received":
        Struct.encode(Struct.wrap(message.variant.ac_project_comment_received), writer.uint32(154).fork()).ldelim();
        break;
      case "cb_project_vote":
        CbProjectVote.encode(message.variant.cb_project_vote, writer.uint32(162).fork()).ldelim();
        break;
      case "cmd_project_add_comment":
        CmdProjectAddComment.encode(message.variant.cmd_project_add_comment, writer.uint32(170).fork()).ldelim();
        break;
      case "cmd_project_remove_comment":
        writer.uint32(176).int64(message.variant.cmd_project_remove_comment);
        break;
      case "cmd_post_new":
        CmdPostUpsert.encode(message.variant.cmd_post_new, writer.uint32(186).fork()).ldelim();
        break;
      case "cmd_post_edit":
        CmdPostUpsert.encode(message.variant.cmd_post_edit, writer.uint32(194).fork()).ldelim();
        break;
      case "cmd_post_react":
        CmdPostReact.encode(message.variant.cmd_post_react, writer.uint32(202).fork()).ldelim();
        break;
      case "cmd_submit_report":
        CmdSubmitReport.encode(message.variant.cmd_submit_report, writer.uint32(210).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RpcMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRpcMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.answer_to = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.variant = { $case: "ping", ping: Empty.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.variant = { $case: "cb_error", cb_error: CbError.decode(reader, reader.uint32()) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.variant = {
            $case: "ac_batch_update",
            ac_batch_update: Struct.unwrap(Struct.decode(reader, reader.uint32())),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.variant = {
            $case: "ac_entity_updated",
            ac_entity_updated: AcEntityUpdated.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.variant = {
            $case: "ac_entity_deleted",
            ac_entity_deleted: AcEntityDeleted.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.variant = { $case: "ac_version_info", ac_version_info: reader.string() };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.variant = {
            $case: "ac_refresh_presence",
            ac_refresh_presence: AcRefreshPresence.decode(reader, reader.uint32()),
          };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.variant = {
            $case: "ac_presence_changed",
            ac_presence_changed: AcPresenceChanged.decode(reader, reader.uint32()),
          };
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.variant = { $case: "cmd_presence_changed", cmd_presence_changed: reader.int32() as any };
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.variant = {
            $case: "cmd_friendship_request",
            cmd_friendship_request: longToNumber(reader.int64() as Long),
          };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.variant = { $case: "cmd_set_status", cmd_set_status: reader.string() };
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.variant = {
            $case: "cmd_send_message",
            cmd_send_message: CmdSendMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.variant = {
            $case: "cb_added_to_chat",
            cb_added_to_chat: CbAddedToChat.decode(reader, reader.uint32()),
          };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.variant = {
            $case: "ac_message_received",
            ac_message_received: Struct.unwrap(Struct.decode(reader, reader.uint32())),
          };
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.variant = {
            $case: "cmd_mark_as_read",
            cmd_mark_as_read: CmdMarkAsRead.decode(reader, reader.uint32()),
          };
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.variant = {
            $case: "cmd_project_vote",
            cmd_project_vote: CmdProjectVote.decode(reader, reader.uint32()),
          };
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.variant = {
            $case: "ac_project_comment_received",
            ac_project_comment_received: Struct.unwrap(Struct.decode(reader, reader.uint32())),
          };
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.variant = {
            $case: "cb_project_vote",
            cb_project_vote: CbProjectVote.decode(reader, reader.uint32()),
          };
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.variant = {
            $case: "cmd_project_add_comment",
            cmd_project_add_comment: CmdProjectAddComment.decode(reader, reader.uint32()),
          };
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.variant = {
            $case: "cmd_project_remove_comment",
            cmd_project_remove_comment: longToNumber(reader.int64() as Long),
          };
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.variant = { $case: "cmd_post_new", cmd_post_new: CmdPostUpsert.decode(reader, reader.uint32()) };
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.variant = { $case: "cmd_post_edit", cmd_post_edit: CmdPostUpsert.decode(reader, reader.uint32()) };
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.variant = { $case: "cmd_post_react", cmd_post_react: CmdPostReact.decode(reader, reader.uint32()) };
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.variant = {
            $case: "cmd_submit_report",
            cmd_submit_report: CmdSubmitReport.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RpcMessage>, I>>(base?: I): RpcMessage {
    return RpcMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RpcMessage>, I>>(object: I): RpcMessage {
    const message = createBaseRpcMessage();
    message.id = object.id ?? 0;
    message.answer_to = object.answer_to ?? undefined;
    if (object.variant?.$case === "ping" && object.variant?.ping !== undefined && object.variant?.ping !== null) {
      message.variant = { $case: "ping", ping: Empty.fromPartial(object.variant.ping) };
    }
    if (
      object.variant?.$case === "cb_error" &&
      object.variant?.cb_error !== undefined &&
      object.variant?.cb_error !== null
    ) {
      message.variant = { $case: "cb_error", cb_error: CbError.fromPartial(object.variant.cb_error) };
    }
    if (
      object.variant?.$case === "ac_batch_update" &&
      object.variant?.ac_batch_update !== undefined &&
      object.variant?.ac_batch_update !== null
    ) {
      message.variant = { $case: "ac_batch_update", ac_batch_update: object.variant.ac_batch_update };
    }
    if (
      object.variant?.$case === "ac_entity_updated" &&
      object.variant?.ac_entity_updated !== undefined &&
      object.variant?.ac_entity_updated !== null
    ) {
      message.variant = {
        $case: "ac_entity_updated",
        ac_entity_updated: AcEntityUpdated.fromPartial(object.variant.ac_entity_updated),
      };
    }
    if (
      object.variant?.$case === "ac_entity_deleted" &&
      object.variant?.ac_entity_deleted !== undefined &&
      object.variant?.ac_entity_deleted !== null
    ) {
      message.variant = {
        $case: "ac_entity_deleted",
        ac_entity_deleted: AcEntityDeleted.fromPartial(object.variant.ac_entity_deleted),
      };
    }
    if (
      object.variant?.$case === "ac_version_info" &&
      object.variant?.ac_version_info !== undefined &&
      object.variant?.ac_version_info !== null
    ) {
      message.variant = { $case: "ac_version_info", ac_version_info: object.variant.ac_version_info };
    }
    if (
      object.variant?.$case === "ac_refresh_presence" &&
      object.variant?.ac_refresh_presence !== undefined &&
      object.variant?.ac_refresh_presence !== null
    ) {
      message.variant = {
        $case: "ac_refresh_presence",
        ac_refresh_presence: AcRefreshPresence.fromPartial(object.variant.ac_refresh_presence),
      };
    }
    if (
      object.variant?.$case === "ac_presence_changed" &&
      object.variant?.ac_presence_changed !== undefined &&
      object.variant?.ac_presence_changed !== null
    ) {
      message.variant = {
        $case: "ac_presence_changed",
        ac_presence_changed: AcPresenceChanged.fromPartial(object.variant.ac_presence_changed),
      };
    }
    if (
      object.variant?.$case === "cmd_presence_changed" &&
      object.variant?.cmd_presence_changed !== undefined &&
      object.variant?.cmd_presence_changed !== null
    ) {
      message.variant = { $case: "cmd_presence_changed", cmd_presence_changed: object.variant.cmd_presence_changed };
    }
    if (
      object.variant?.$case === "cmd_friendship_request" &&
      object.variant?.cmd_friendship_request !== undefined &&
      object.variant?.cmd_friendship_request !== null
    ) {
      message.variant = {
        $case: "cmd_friendship_request",
        cmd_friendship_request: object.variant.cmd_friendship_request,
      };
    }
    if (
      object.variant?.$case === "cmd_set_status" &&
      object.variant?.cmd_set_status !== undefined &&
      object.variant?.cmd_set_status !== null
    ) {
      message.variant = { $case: "cmd_set_status", cmd_set_status: object.variant.cmd_set_status };
    }
    if (
      object.variant?.$case === "cmd_send_message" &&
      object.variant?.cmd_send_message !== undefined &&
      object.variant?.cmd_send_message !== null
    ) {
      message.variant = {
        $case: "cmd_send_message",
        cmd_send_message: CmdSendMessage.fromPartial(object.variant.cmd_send_message),
      };
    }
    if (
      object.variant?.$case === "cb_added_to_chat" &&
      object.variant?.cb_added_to_chat !== undefined &&
      object.variant?.cb_added_to_chat !== null
    ) {
      message.variant = {
        $case: "cb_added_to_chat",
        cb_added_to_chat: CbAddedToChat.fromPartial(object.variant.cb_added_to_chat),
      };
    }
    if (
      object.variant?.$case === "ac_message_received" &&
      object.variant?.ac_message_received !== undefined &&
      object.variant?.ac_message_received !== null
    ) {
      message.variant = { $case: "ac_message_received", ac_message_received: object.variant.ac_message_received };
    }
    if (
      object.variant?.$case === "cmd_mark_as_read" &&
      object.variant?.cmd_mark_as_read !== undefined &&
      object.variant?.cmd_mark_as_read !== null
    ) {
      message.variant = {
        $case: "cmd_mark_as_read",
        cmd_mark_as_read: CmdMarkAsRead.fromPartial(object.variant.cmd_mark_as_read),
      };
    }
    if (
      object.variant?.$case === "cmd_project_vote" &&
      object.variant?.cmd_project_vote !== undefined &&
      object.variant?.cmd_project_vote !== null
    ) {
      message.variant = {
        $case: "cmd_project_vote",
        cmd_project_vote: CmdProjectVote.fromPartial(object.variant.cmd_project_vote),
      };
    }
    if (
      object.variant?.$case === "ac_project_comment_received" &&
      object.variant?.ac_project_comment_received !== undefined &&
      object.variant?.ac_project_comment_received !== null
    ) {
      message.variant = {
        $case: "ac_project_comment_received",
        ac_project_comment_received: object.variant.ac_project_comment_received,
      };
    }
    if (
      object.variant?.$case === "cb_project_vote" &&
      object.variant?.cb_project_vote !== undefined &&
      object.variant?.cb_project_vote !== null
    ) {
      message.variant = {
        $case: "cb_project_vote",
        cb_project_vote: CbProjectVote.fromPartial(object.variant.cb_project_vote),
      };
    }
    if (
      object.variant?.$case === "cmd_project_add_comment" &&
      object.variant?.cmd_project_add_comment !== undefined &&
      object.variant?.cmd_project_add_comment !== null
    ) {
      message.variant = {
        $case: "cmd_project_add_comment",
        cmd_project_add_comment: CmdProjectAddComment.fromPartial(object.variant.cmd_project_add_comment),
      };
    }
    if (
      object.variant?.$case === "cmd_project_remove_comment" &&
      object.variant?.cmd_project_remove_comment !== undefined &&
      object.variant?.cmd_project_remove_comment !== null
    ) {
      message.variant = {
        $case: "cmd_project_remove_comment",
        cmd_project_remove_comment: object.variant.cmd_project_remove_comment,
      };
    }
    if (
      object.variant?.$case === "cmd_post_new" &&
      object.variant?.cmd_post_new !== undefined &&
      object.variant?.cmd_post_new !== null
    ) {
      message.variant = { $case: "cmd_post_new", cmd_post_new: CmdPostUpsert.fromPartial(object.variant.cmd_post_new) };
    }
    if (
      object.variant?.$case === "cmd_post_edit" &&
      object.variant?.cmd_post_edit !== undefined &&
      object.variant?.cmd_post_edit !== null
    ) {
      message.variant = {
        $case: "cmd_post_edit",
        cmd_post_edit: CmdPostUpsert.fromPartial(object.variant.cmd_post_edit),
      };
    }
    if (
      object.variant?.$case === "cmd_post_react" &&
      object.variant?.cmd_post_react !== undefined &&
      object.variant?.cmd_post_react !== null
    ) {
      message.variant = {
        $case: "cmd_post_react",
        cmd_post_react: CmdPostReact.fromPartial(object.variant.cmd_post_react),
      };
    }
    if (
      object.variant?.$case === "cmd_submit_report" &&
      object.variant?.cmd_submit_report !== undefined &&
      object.variant?.cmd_submit_report !== null
    ) {
      message.variant = {
        $case: "cmd_submit_report",
        cmd_submit_report: CmdSubmitReport.fromPartial(object.variant.cmd_submit_report),
      };
    }
    return message;
  },
};

function createBaseCbError(): CbError {
  return { description: "" };
}

export const CbError = {
  encode(message: CbError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CbError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCbError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CbError>, I>>(base?: I): CbError {
    return CbError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CbError>, I>>(object: I): CbError {
    const message = createBaseCbError();
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseAcEntityUpdated(): AcEntityUpdated {
  return { entity_type: 0, num: 0, entity: undefined, update_type: 0 };
}

export const AcEntityUpdated = {
  encode(message: AcEntityUpdated, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entity_type !== 0) {
      writer.uint32(8).int32(message.entity_type);
    }
    if (message.num !== 0) {
      writer.uint32(16).int64(message.num);
    }
    if (message.entity !== undefined) {
      Struct.encode(Struct.wrap(message.entity), writer.uint32(26).fork()).ldelim();
    }
    if (message.update_type !== 0) {
      writer.uint32(32).int32(message.update_type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcEntityUpdated {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcEntityUpdated();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.entity_type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.num = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entity = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.update_type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AcEntityUpdated>, I>>(base?: I): AcEntityUpdated {
    return AcEntityUpdated.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcEntityUpdated>, I>>(object: I): AcEntityUpdated {
    const message = createBaseAcEntityUpdated();
    message.entity_type = object.entity_type ?? 0;
    message.num = object.num ?? 0;
    message.entity = object.entity ?? undefined;
    message.update_type = object.update_type ?? 0;
    return message;
  },
};

function createBaseAcEntityDeleted(): AcEntityDeleted {
  return { entity_type: 0, num: 0 };
}

export const AcEntityDeleted = {
  encode(message: AcEntityDeleted, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entity_type !== 0) {
      writer.uint32(8).int32(message.entity_type);
    }
    if (message.num !== 0) {
      writer.uint32(16).int64(message.num);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcEntityDeleted {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcEntityDeleted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.entity_type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.num = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AcEntityDeleted>, I>>(base?: I): AcEntityDeleted {
    return AcEntityDeleted.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcEntityDeleted>, I>>(object: I): AcEntityDeleted {
    const message = createBaseAcEntityDeleted();
    message.entity_type = object.entity_type ?? 0;
    message.num = object.num ?? 0;
    return message;
  },
};

function createBaseCmdProjectAddComment(): CmdProjectAddComment {
  return { project_num: 0, reply_to: undefined, text: undefined, mentioned_nicknames: [], attachments: [] };
}

export const CmdProjectAddComment = {
  encode(message: CmdProjectAddComment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project_num !== 0) {
      writer.uint32(8).int64(message.project_num);
    }
    if (message.reply_to !== undefined) {
      writer.uint32(16).int64(message.reply_to);
    }
    if (message.text !== undefined) {
      writer.uint32(26).string(message.text);
    }
    for (const v of message.mentioned_nicknames) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.attachments) {
      MessageAttachment.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CmdProjectAddComment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCmdProjectAddComment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.project_num = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.reply_to = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.text = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mentioned_nicknames.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.attachments.push(MessageAttachment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CmdProjectAddComment>, I>>(base?: I): CmdProjectAddComment {
    return CmdProjectAddComment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CmdProjectAddComment>, I>>(object: I): CmdProjectAddComment {
    const message = createBaseCmdProjectAddComment();
    message.project_num = object.project_num ?? 0;
    message.reply_to = object.reply_to ?? undefined;
    message.text = object.text ?? undefined;
    message.mentioned_nicknames = object.mentioned_nicknames?.map((e) => e) || [];
    message.attachments = object.attachments?.map((e) => MessageAttachment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCmdProjectVote(): CmdProjectVote {
  return { project_num: 0, upvote: false, comment: undefined };
}

export const CmdProjectVote = {
  encode(message: CmdProjectVote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project_num !== 0) {
      writer.uint32(8).int64(message.project_num);
    }
    if (message.upvote !== false) {
      writer.uint32(16).bool(message.upvote);
    }
    if (message.comment !== undefined) {
      writer.uint32(26).string(message.comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CmdProjectVote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCmdProjectVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.project_num = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.upvote = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CmdProjectVote>, I>>(base?: I): CmdProjectVote {
    return CmdProjectVote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CmdProjectVote>, I>>(object: I): CmdProjectVote {
    const message = createBaseCmdProjectVote();
    message.project_num = object.project_num ?? 0;
    message.upvote = object.upvote ?? false;
    message.comment = object.comment ?? undefined;
    return message;
  },
};

function createBaseCbProjectVote(): CbProjectVote {
  return { variant: undefined };
}

export const CbProjectVote = {
  encode(message: CbProjectVote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.variant?.$case) {
      case "cancelled_vote":
        writer.uint32(8).int64(message.variant.cancelled_vote);
        break;
      case "updated_vote":
        Struct.encode(Struct.wrap(message.variant.updated_vote), writer.uint32(18).fork()).ldelim();
        break;
      case "created_vote":
        Struct.encode(Struct.wrap(message.variant.created_vote), writer.uint32(26).fork()).ldelim();
        break;
      case "error_pool_empty":
        writer.uint32(32).int64(message.variant.error_pool_empty);
        break;
      case "error_no_comment":
        Empty.encode(message.variant.error_no_comment, writer.uint32(42).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CbProjectVote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCbProjectVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.variant = { $case: "cancelled_vote", cancelled_vote: longToNumber(reader.int64() as Long) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.variant = {
            $case: "updated_vote",
            updated_vote: Struct.unwrap(Struct.decode(reader, reader.uint32())),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.variant = {
            $case: "created_vote",
            created_vote: Struct.unwrap(Struct.decode(reader, reader.uint32())),
          };
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.variant = { $case: "error_pool_empty", error_pool_empty: longToNumber(reader.int64() as Long) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.variant = { $case: "error_no_comment", error_no_comment: Empty.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CbProjectVote>, I>>(base?: I): CbProjectVote {
    return CbProjectVote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CbProjectVote>, I>>(object: I): CbProjectVote {
    const message = createBaseCbProjectVote();
    if (
      object.variant?.$case === "cancelled_vote" &&
      object.variant?.cancelled_vote !== undefined &&
      object.variant?.cancelled_vote !== null
    ) {
      message.variant = { $case: "cancelled_vote", cancelled_vote: object.variant.cancelled_vote };
    }
    if (
      object.variant?.$case === "updated_vote" &&
      object.variant?.updated_vote !== undefined &&
      object.variant?.updated_vote !== null
    ) {
      message.variant = { $case: "updated_vote", updated_vote: object.variant.updated_vote };
    }
    if (
      object.variant?.$case === "created_vote" &&
      object.variant?.created_vote !== undefined &&
      object.variant?.created_vote !== null
    ) {
      message.variant = { $case: "created_vote", created_vote: object.variant.created_vote };
    }
    if (
      object.variant?.$case === "error_pool_empty" &&
      object.variant?.error_pool_empty !== undefined &&
      object.variant?.error_pool_empty !== null
    ) {
      message.variant = { $case: "error_pool_empty", error_pool_empty: object.variant.error_pool_empty };
    }
    if (
      object.variant?.$case === "error_no_comment" &&
      object.variant?.error_no_comment !== undefined &&
      object.variant?.error_no_comment !== null
    ) {
      message.variant = {
        $case: "error_no_comment",
        error_no_comment: Empty.fromPartial(object.variant.error_no_comment),
      };
    }
    return message;
  },
};

function createBaseCbAddedToChat(): CbAddedToChat {
  return { chat_num: 0 };
}

export const CbAddedToChat = {
  encode(message: CbAddedToChat, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chat_num !== 0) {
      writer.uint32(8).int64(message.chat_num);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CbAddedToChat {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCbAddedToChat();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.chat_num = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CbAddedToChat>, I>>(base?: I): CbAddedToChat {
    return CbAddedToChat.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CbAddedToChat>, I>>(object: I): CbAddedToChat {
    const message = createBaseCbAddedToChat();
    message.chat_num = object.chat_num ?? 0;
    return message;
  },
};

function createBaseCmdSendMessage(): CmdSendMessage {
  return { to_nickname: "", text: undefined, attachments: [] };
}

export const CmdSendMessage = {
  encode(message: CmdSendMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.to_nickname !== "") {
      writer.uint32(10).string(message.to_nickname);
    }
    if (message.text !== undefined) {
      writer.uint32(18).string(message.text);
    }
    for (const v of message.attachments) {
      MessageAttachment.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CmdSendMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCmdSendMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.to_nickname = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.attachments.push(MessageAttachment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CmdSendMessage>, I>>(base?: I): CmdSendMessage {
    return CmdSendMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CmdSendMessage>, I>>(object: I): CmdSendMessage {
    const message = createBaseCmdSendMessage();
    message.to_nickname = object.to_nickname ?? "";
    message.text = object.text ?? undefined;
    message.attachments = object.attachments?.map((e) => MessageAttachment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMessageAttachment(): MessageAttachment {
  return { attachment: undefined };
}

export const MessageAttachment = {
  encode(message: MessageAttachment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.attachment?.$case) {
      case "voice":
        VoiceAttachment.encode(message.attachment.voice, writer.uint32(10).fork()).ldelim();
        break;
      case "picture":
        PictureAttachment.encode(message.attachment.picture, writer.uint32(18).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageAttachment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageAttachment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attachment = { $case: "voice", voice: VoiceAttachment.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.attachment = { $case: "picture", picture: PictureAttachment.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MessageAttachment>, I>>(base?: I): MessageAttachment {
    return MessageAttachment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MessageAttachment>, I>>(object: I): MessageAttachment {
    const message = createBaseMessageAttachment();
    if (
      object.attachment?.$case === "voice" &&
      object.attachment?.voice !== undefined &&
      object.attachment?.voice !== null
    ) {
      message.attachment = { $case: "voice", voice: VoiceAttachment.fromPartial(object.attachment.voice) };
    }
    if (
      object.attachment?.$case === "picture" &&
      object.attachment?.picture !== undefined &&
      object.attachment?.picture !== null
    ) {
      message.attachment = { $case: "picture", picture: PictureAttachment.fromPartial(object.attachment.picture) };
    }
    return message;
  },
};

function createBasePictureAttachment(): PictureAttachment {
  return { data: new Uint8Array(0), mime_type: "" };
}

export const PictureAttachment = {
  encode(message: PictureAttachment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data.length !== 0) {
      writer.uint32(10).bytes(message.data);
    }
    if (message.mime_type !== "") {
      writer.uint32(18).string(message.mime_type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PictureAttachment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePictureAttachment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mime_type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PictureAttachment>, I>>(base?: I): PictureAttachment {
    return PictureAttachment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PictureAttachment>, I>>(object: I): PictureAttachment {
    const message = createBasePictureAttachment();
    message.data = object.data ?? new Uint8Array(0);
    message.mime_type = object.mime_type ?? "";
    return message;
  },
};

function createBaseVoiceAttachment(): VoiceAttachment {
  return { data: new Uint8Array(0), duration_ms: 0, mime_type: "", waveform: new Uint8Array(0) };
}

export const VoiceAttachment = {
  encode(message: VoiceAttachment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data.length !== 0) {
      writer.uint32(10).bytes(message.data);
    }
    if (message.duration_ms !== 0) {
      writer.uint32(16).uint32(message.duration_ms);
    }
    if (message.mime_type !== "") {
      writer.uint32(26).string(message.mime_type);
    }
    if (message.waveform.length !== 0) {
      writer.uint32(34).bytes(message.waveform);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VoiceAttachment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoiceAttachment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.duration_ms = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mime_type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.waveform = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<VoiceAttachment>, I>>(base?: I): VoiceAttachment {
    return VoiceAttachment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoiceAttachment>, I>>(object: I): VoiceAttachment {
    const message = createBaseVoiceAttachment();
    message.data = object.data ?? new Uint8Array(0);
    message.duration_ms = object.duration_ms ?? 0;
    message.mime_type = object.mime_type ?? "";
    message.waveform = object.waveform ?? new Uint8Array(0);
    return message;
  },
};

function createBaseCmdProjectUpsert(): CmdProjectUpsert {
  return {
    num: undefined,
    name: undefined,
    description: undefined,
    website: undefined,
    sphere: undefined,
    tags: [],
    avatar: undefined,
    background: undefined,
    gallery: [],
  };
}

export const CmdProjectUpsert = {
  encode(message: CmdProjectUpsert, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.num !== undefined) {
      writer.uint32(8).int64(message.num);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.website !== undefined) {
      writer.uint32(34).string(message.website);
    }
    if (message.sphere !== undefined) {
      writer.uint32(42).string(message.sphere);
    }
    for (const v of message.tags) {
      Struct.encode(Struct.wrap(v!), writer.uint32(50).fork()).ldelim();
    }
    if (message.avatar !== undefined) {
      ImageEditDescriptor.encode(message.avatar, writer.uint32(58).fork()).ldelim();
    }
    if (message.background !== undefined) {
      ImageEditDescriptor.encode(message.background, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.gallery) {
      ImageEditDescriptor.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CmdProjectUpsert {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCmdProjectUpsert();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.num = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.website = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sphere = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tags.push(Struct.unwrap(Struct.decode(reader, reader.uint32())));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.avatar = ImageEditDescriptor.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.background = ImageEditDescriptor.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.gallery.push(ImageEditDescriptor.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CmdProjectUpsert>, I>>(base?: I): CmdProjectUpsert {
    return CmdProjectUpsert.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CmdProjectUpsert>, I>>(object: I): CmdProjectUpsert {
    const message = createBaseCmdProjectUpsert();
    message.num = object.num ?? undefined;
    message.name = object.name ?? undefined;
    message.description = object.description ?? undefined;
    message.website = object.website ?? undefined;
    message.sphere = object.sphere ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.avatar = (object.avatar !== undefined && object.avatar !== null)
      ? ImageEditDescriptor.fromPartial(object.avatar)
      : undefined;
    message.background = (object.background !== undefined && object.background !== null)
      ? ImageEditDescriptor.fromPartial(object.background)
      : undefined;
    message.gallery = object.gallery?.map((e) => ImageEditDescriptor.fromPartial(e)) || [];
    return message;
  },
};

function createBaseImageEditDescriptor(): ImageEditDescriptor {
  return { type: undefined };
}

export const ImageEditDescriptor = {
  encode(message: ImageEditDescriptor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.type?.$case) {
      case "replacement":
        writer.uint32(10).bytes(message.type.replacement);
        break;
      case "key":
        writer.uint32(18).string(message.type.key);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageEditDescriptor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageEditDescriptor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = { $case: "replacement", replacement: reader.bytes() };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = { $case: "key", key: reader.string() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ImageEditDescriptor>, I>>(base?: I): ImageEditDescriptor {
    return ImageEditDescriptor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageEditDescriptor>, I>>(object: I): ImageEditDescriptor {
    const message = createBaseImageEditDescriptor();
    if (
      object.type?.$case === "replacement" &&
      object.type?.replacement !== undefined &&
      object.type?.replacement !== null
    ) {
      message.type = { $case: "replacement", replacement: object.type.replacement };
    }
    if (object.type?.$case === "key" && object.type?.key !== undefined && object.type?.key !== null) {
      message.type = { $case: "key", key: object.type.key };
    }
    return message;
  },
};

function createBaseCmdPostUpsert(): CmdPostUpsert {
  return { text: undefined, tag_nums: [], images: [] };
}

export const CmdPostUpsert = {
  encode(message: CmdPostUpsert, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.text !== undefined) {
      writer.uint32(10).string(message.text);
    }
    writer.uint32(18).fork();
    for (const v of message.tag_nums) {
      writer.int64(v);
    }
    writer.ldelim();
    for (const v of message.images) {
      writer.uint32(26).bytes(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CmdPostUpsert {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCmdPostUpsert();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.text = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.tag_nums.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tag_nums.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.images.push(reader.bytes());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CmdPostUpsert>, I>>(base?: I): CmdPostUpsert {
    return CmdPostUpsert.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CmdPostUpsert>, I>>(object: I): CmdPostUpsert {
    const message = createBaseCmdPostUpsert();
    message.text = object.text ?? undefined;
    message.tag_nums = object.tag_nums?.map((e) => e) || [];
    message.images = object.images?.map((e) => e) || [];
    return message;
  },
};

function createBaseCmdPostReact(): CmdPostReact {
  return { post_num: 0, emoji: "" };
}

export const CmdPostReact = {
  encode(message: CmdPostReact, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.post_num !== 0) {
      writer.uint32(8).int64(message.post_num);
    }
    if (message.emoji !== "") {
      writer.uint32(18).string(message.emoji);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CmdPostReact {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCmdPostReact();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.post_num = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.emoji = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CmdPostReact>, I>>(base?: I): CmdPostReact {
    return CmdPostReact.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CmdPostReact>, I>>(object: I): CmdPostReact {
    const message = createBaseCmdPostReact();
    message.post_num = object.post_num ?? 0;
    message.emoji = object.emoji ?? "";
    return message;
  },
};

function createBaseCmdMarkAsRead(): CmdMarkAsRead {
  return { chat_num: 0, last_read_message_num: 0 };
}

export const CmdMarkAsRead = {
  encode(message: CmdMarkAsRead, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chat_num !== 0) {
      writer.uint32(8).int64(message.chat_num);
    }
    if (message.last_read_message_num !== 0) {
      writer.uint32(16).int64(message.last_read_message_num);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CmdMarkAsRead {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCmdMarkAsRead();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.chat_num = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.last_read_message_num = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CmdMarkAsRead>, I>>(base?: I): CmdMarkAsRead {
    return CmdMarkAsRead.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CmdMarkAsRead>, I>>(object: I): CmdMarkAsRead {
    const message = createBaseCmdMarkAsRead();
    message.chat_num = object.chat_num ?? 0;
    message.last_read_message_num = object.last_read_message_num ?? 0;
    return message;
  },
};

function createBaseAcProjectScoreChanged(): AcProjectScoreChanged {
  return { project_num: 0, score: 0 };
}

export const AcProjectScoreChanged = {
  encode(message: AcProjectScoreChanged, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project_num !== 0) {
      writer.uint32(8).int64(message.project_num);
    }
    if (message.score !== 0) {
      writer.uint32(16).int64(message.score);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcProjectScoreChanged {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcProjectScoreChanged();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.project_num = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.score = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AcProjectScoreChanged>, I>>(base?: I): AcProjectScoreChanged {
    return AcProjectScoreChanged.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcProjectScoreChanged>, I>>(object: I): AcProjectScoreChanged {
    const message = createBaseAcProjectScoreChanged();
    message.project_num = object.project_num ?? 0;
    message.score = object.score ?? 0;
    return message;
  },
};

function createBaseProjectVote(): ProjectVote {
  return { project_num: 0, bet: 0 };
}

export const ProjectVote = {
  encode(message: ProjectVote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project_num !== 0) {
      writer.uint32(8).int64(message.project_num);
    }
    if (message.bet !== 0) {
      writer.uint32(16).int64(message.bet);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProjectVote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.project_num = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bet = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProjectVote>, I>>(base?: I): ProjectVote {
    return ProjectVote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProjectVote>, I>>(object: I): ProjectVote {
    const message = createBaseProjectVote();
    message.project_num = object.project_num ?? 0;
    message.bet = object.bet ?? 0;
    return message;
  },
};

function createBaseAcRefreshPresence(): AcRefreshPresence {
  return { map: {} };
}

export const AcRefreshPresence = {
  encode(message: AcRefreshPresence, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.map).forEach(([key, value]) => {
      AcRefreshPresence_MapEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcRefreshPresence {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcRefreshPresence();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = AcRefreshPresence_MapEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.map[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AcRefreshPresence>, I>>(base?: I): AcRefreshPresence {
    return AcRefreshPresence.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcRefreshPresence>, I>>(object: I): AcRefreshPresence {
    const message = createBaseAcRefreshPresence();
    message.map = Object.entries(object.map ?? {}).reduce<{ [key: number]: OnlineStatus }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = value as OnlineStatus;
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseAcRefreshPresence_MapEntry(): AcRefreshPresence_MapEntry {
  return { key: 0, value: 0 };
}

export const AcRefreshPresence_MapEntry = {
  encode(message: AcRefreshPresence_MapEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcRefreshPresence_MapEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcRefreshPresence_MapEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AcRefreshPresence_MapEntry>, I>>(base?: I): AcRefreshPresence_MapEntry {
    return AcRefreshPresence_MapEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcRefreshPresence_MapEntry>, I>>(object: I): AcRefreshPresence_MapEntry {
    const message = createBaseAcRefreshPresence_MapEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseAcPresenceChanged(): AcPresenceChanged {
  return { user_num: 0, status: 0, last_seen_at: undefined };
}

export const AcPresenceChanged = {
  encode(message: AcPresenceChanged, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_num !== 0) {
      writer.uint32(8).int64(message.user_num);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.last_seen_at !== undefined) {
      writer.uint32(24).int64(message.last_seen_at);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcPresenceChanged {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcPresenceChanged();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_num = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.last_seen_at = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AcPresenceChanged>, I>>(base?: I): AcPresenceChanged {
    return AcPresenceChanged.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcPresenceChanged>, I>>(object: I): AcPresenceChanged {
    const message = createBaseAcPresenceChanged();
    message.user_num = object.user_num ?? 0;
    message.status = object.status ?? 0;
    message.last_seen_at = object.last_seen_at ?? undefined;
    return message;
  },
};

function createBaseCmdSubmitReport(): CmdSubmitReport {
  return { entity_type: 0, entity_num: 0, reason: "" };
}

export const CmdSubmitReport = {
  encode(message: CmdSubmitReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entity_type !== 0) {
      writer.uint32(8).int32(message.entity_type);
    }
    if (message.entity_num !== 0) {
      writer.uint32(16).int64(message.entity_num);
    }
    if (message.reason !== "") {
      writer.uint32(26).string(message.reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CmdSubmitReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCmdSubmitReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.entity_type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.entity_num = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.reason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CmdSubmitReport>, I>>(base?: I): CmdSubmitReport {
    return CmdSubmitReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CmdSubmitReport>, I>>(object: I): CmdSubmitReport {
    const message = createBaseCmdSubmitReport();
    message.entity_type = object.entity_type ?? 0;
    message.entity_num = object.entity_num ?? 0;
    message.reason = object.reason ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
