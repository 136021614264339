export let LocalStorageKeys = {
	OTP_TOKEN: "OTP_TOKEN",
	JWT: "JWT",
	LOGIN_VIEW: "LOGIN_VIEW",
	ONBOARDING_VIEW: "ONBOARDING_VIEW",
	BUSINESS_VIEW: "BUSINESS_VIEW",

	DEALS_VIEW: "DEALS_VIEW",
	EDIT_VACANCY_VIEW_STEP: "CREATE_VACANCY_VIEW_STEP",
	CREATE_VACANCY: "CREATE_VACANCY",

	EDIT_BEGGING_VIEW_STEP: "EDIT_BEGGING_VIEW",

	CREATE_INVESTMENT_VIEW: "CREATE_INVESTMENT_VIEW",
	CREATE_INVESTMENT: "CREATE_INVESTMENT",

	CHATS_VIEW: "CHATS_VIEW",

	EDIT_PROJECT: "EDIT_PROJECT_",

	SEARCH_DEALS_VIEW: "SEARCH_DEALS_VIEW",
} as const
