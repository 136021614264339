import { createOverlay } from "#/lib/overlay"
import { clamp } from "@sferadel/ts-lib"
import { createEffect, onCleanup } from "solid-js"
import { createMutable } from "solid-js/store"
import { ItemIconWrapper } from "./item-icon-wrapper"
import { useRouter } from "#/core/navigation/navigation"

let INITIAL_VALUES = {
	x: 0,
	y: 0,
	scale: 1,
}

let MIN_SCALE = 1
let MAX_SCALE = 4

// TODO: better navigation handling
export function imageviewer(target: HTMLElement, {}) {
	let overlay = createOverlay()
	let router = useRouter()

	let state = createMutable({
		...INITIAL_VALUES,
		ref: null as HTMLElement,
	})

	target.addEventListener("click", onTargetClick)
	onCleanup(() => target.removeEventListener("click", onTargetClick))

	function onTargetClick(e) {
		router.commit({ path: router.view.rpath, force: "push", state: { overlay: true } })
		overlay.show = true

		e.stopPropagation()
	}

	window.addEventListener("popstate", e => {
		if (overlay.show) {
			overlay.show = false
			router.commit({ path: router.view.rpath, force: "replace" })
		}
	})

	createEffect(() => {
		if (!state.ref) return

		let x = Math.round(state.x), y = Math.round(state.y), s = state.scale.toFixed(3)
		state.ref.style.transform = `translate3d(calc(${x}px - 50%), calc(${y}px - 50%), 0px) scale(${s})`
	})

	let prev_pointer: PointerEvent = null
	let prev_touch_1: Touch = null, prev_touch_2: Touch = null
	let prev_fingers_dist: number = null
	let prev_fingers_center: { x: number; y: number } = null

	let clampScale = (value: number) => clamp(value, MIN_SCALE, MAX_SCALE)

	function cancelZoom() {
		prev_touch_1 = null
		prev_touch_2 = null
		prev_pointer = null
		prev_fingers_dist = null
		prev_fingers_center = null
	}

	<overlay.VirtualRoot
		safe_position={false}
		cancellable={false}
		Background={p => (
			<div
				{...p}
				class={`${p.class} dark:bg-gray-800/90 light:bg-gray-000/90 backdrop-blur-2px`}
				children={[
					<ItemIconWrapper
						class="cursor-pointer absolute! right-2 top-[calc(env(safe-area-inset-top,0)+1rem)] z103 [&>div:nth-child(1)]:(active:bg-gray-900/90! hover:bg-gray-900/90!)"
						children={<i class="i-hero:x-mark" />}
						circle_cl="bg-gray-900/30"
						onClick={_ => {
							overlay.show = false
							router.commit({ path: router.view.rpath, force: "back" })
						}}
					/>,
					p.children,
				]}
			/>
		)}
		Content={() => {
			let ref = target.cloneNode(true) as HTMLElement
			ref.className = "abs-centered"
			ref.style.width = "100%"
			ref.style.height = "auto"
			ref.draggable = false

			function pinchAndZoom(target_x: number, target_y: number, d_z: number) {
				let scale_start = state.scale
				let { width, height } = ref.getBoundingClientRect()

				state.scale = clampScale(state.scale + d_z)

				let dx = 0, dy = 0
				if (d_z < 0 && state.scale > 1) {
					let steps_to_initial_scale = (state.scale - MIN_SCALE) / d_z

					dx = state.x / steps_to_initial_scale
					dy = state.y / steps_to_initial_scale
				}
				else {
					let steps_to_max_scale = 1
					if (d_z > 0 && state.scale < MAX_SCALE) {
						steps_to_max_scale = MAX_SCALE - state.scale
					}

					dx = target_x / steps_to_max_scale
					dy = target_y / steps_to_max_scale
				}

				width = state.scale * (width / scale_start)
				height = state.scale * (height / scale_start)

				let max_allowed_pan_x = Math.max((width - window.innerWidth) / 2, 0)
				state.x = clamp(state.x + dx, -max_allowed_pan_x, max_allowed_pan_x)

				let max_allowed_pan_y = Math.max((height - window.innerHeight) / 2, 0)
				state.y = clamp(state.y + dy, -max_allowed_pan_y, max_allowed_pan_y)
			}

			// in chrome attaching wheel event to ref doesn't work -_-. idk
			document.body.addEventListener("wheel", (e) => {
				pinchAndZoom(window.innerWidth / 2 - e.pageX, window.innerHeight / 2 - e.pageY, -e.deltaY * 0.003)
			}, { passive: true })

			function onPointerMove(e: PointerEvent) {
				if (!prev_pointer) {
					ref.classList.add("cursor-move")
					prev_pointer = e
					return
				}

				pinchAndZoom(
					(e.clientX - prev_pointer.clientX) * 0.6,
					(e.clientY - prev_pointer.clientY) * 0.6,
					0,
				)

				prev_pointer = e
			}

			ref.addEventListener("pointerdown", (e) => {
				if (e.pointerType === "touch") return

				ref.setPointerCapture(e.pointerId)
				window.addEventListener("pointermove", onPointerMove, { passive: true })

				window.addEventListener("pointerup", e => {
					window.removeEventListener("pointermove", onPointerMove)
					ref.releasePointerCapture(e.pointerId)

					prev_pointer = null
					ref.classList.remove("cursor-move")
				})
			})

			ref.addEventListener("touchmove", (e) => {
				let [touch1, touch2] = e.touches

				if (touch1 && !touch2) {
					if (prev_pointer) {
						let t_x = (touch1.clientX - prev_pointer.clientX) * 0.8
						let t_y = (touch1.clientY - prev_pointer.clientY) * 0.8
						pinchAndZoom(t_x, t_y, 0)
					}
					prev_pointer = touch1
					return
				}

				// dprint-ignore
				let fingers_dist = Math.sqrt((touch2.clientX - touch1.clientX) ** 2 + (touch2.clientY - touch1.clientY) ** 2)

				let fingers_center = {
					x: Math.min(touch1.clientX, touch2.clientX) + Math.abs((touch2.clientX - touch1.clientX) / 2),
					y: Math.min(touch1.clientY, touch2.clientY) + Math.abs((touch2.clientY - touch1.clientY) / 2),
				}

				if (prev_touch_1 && prev_touch_2 && prev_fingers_dist != null && prev_fingers_center != null) {
					let dz = (prev_fingers_dist - fingers_dist) * -0.01
					let tx = (fingers_center.x - prev_fingers_center.x) * 2
					let ty = (fingers_center.y - prev_fingers_center.y) * 2
					pinchAndZoom(tx, ty, dz)
				}

				prev_touch_1 = touch1
				prev_touch_2 = touch2
				prev_fingers_dist = fingers_dist
				prev_fingers_center = fingers_center
			}, { passive: true })

			ref.addEventListener("touchcancel", cancelZoom)
			ref.addEventListener("touchend", cancelZoom)

			state.ref = ref
			return ref
		}}
	/>

	// no return lmao
}
