import type { shapes } from "#/lib/shapes"
import { batch, createContext, type ParentProps, useContext } from "solid-js"
import { createMutable, unwrap } from "solid-js/store"
import { mergeMutableAdditive } from "#/lib/utils"
import type * as proto from "#/proto/schema"

export let IdbStoreKeys = {
	ProjectForms: "project_forms",
	VacancyForms: "vacancy_forms",
	BeggingForms: "begging_forms",
	InvestmentForms: "investment_forms",
}

export function CacheContextProvider(props: ParentProps) {
	let idb: IDBDatabase

	let entities = createMutable({
		users: [] as (shapes.MinUser | shapes.User)[],
		presense_list: [] as { num: number; status: proto.OnlineStatus }[],

		tags: [] as shapes.Tag[],

		chats: [] as shapes.MinChat[],
		messages: [] as shapes.Message[],
		chat_memberships: [] as shapes.MinChatMembership[],

		deals: [] as shapes.MinDeal[],

		projects: [] as shapes.MinProject[],
		project_votes: [] as shapes.ProjectVote[],
		project_comments: [] as shapes.ProjectComment[],

		posts: [] as shapes.MinPost[],
	})

	let misc = createMutable({
		watched_deals: [] as number[],
		addWatchedDeals(deals: number[]) {
			deals.forEach(deal => !misc.watched_deals.includes(deal) && misc.watched_deals.push(deal))
		},
	})

	// TODO
	Object.assign(window, {
		get cache() {
			return unwrap({ ...entities, ...misc })
		},
	})

	load()

	type EntitiesStorage = typeof entities
	type SpecialKeys = Partial<
		{
			[key in keyof EntitiesStorage]: keyof EntitiesStorage[key][number] extends string
				? keyof EntitiesStorage[key][number]
				: never
		}
	>
	let special_keys: SpecialKeys = {
		chat_memberships: "chat_num",
	}

	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		num: number,
		key?: keyof EntitiesStorage[K][number],
	): EntitiesStorage[K][number]
	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		nums: number[],
		key?: keyof EntitiesStorage[K][number],
	): EntitiesStorage[K]
	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		nums: number | number[],
		key = special_keys[name] ?? "num",
	) {
		if (Array.isArray(nums)) {
			let resolved = nums.flatMap(num => entities[name].filter(item => item[key as keyof typeof item] === num))
			return resolved.filter(Boolean)
		}
		let resolved = entities[name].find(item => item[key as keyof typeof item] === nums)
		return resolved
	}

	function update<K extends keyof EntitiesStorage>(key: K, values: EntitiesStorage[K]) {
		type V = EntitiesStorage[K][number]
		mergeMutableAdditive<V, V>(entities[key], values, {
			merge: true,
			key: special_keys[key] ?? "num",
		})
	}

	function updateBatch<K extends keyof EntitiesStorage>(obj: Record<K, EntitiesStorage[K]>) {
		batch(() => Object.keys(obj).forEach(key => (key in entities) && update(key as K, obj[key])))
	}

	function drop() {
		batch(() => {
			for (let storage of Object.getOwnPropertyNames(entities)) {
				entities[storage].splice(0, entities[storage].length)
			}
		})
		console.log("in-memory cache cleared")
	}

	async function load() {
		let db_request = indexedDB.open("sferadel", 8)

		await new Promise<void>((resolve) => {
			db_request.onupgradeneeded = change_event => {
				idb = db_request.result

				let { oldVersion, newVersion } = change_event
				console.warn(`indexed db upgrade needed: ${oldVersion} -> ${newVersion}`)

				let { objectStoreNames } = idb
				Object.keys(entities).forEach(entity_name => {
					objectStoreNames.contains(entity_name) && idb.deleteObjectStore(entity_name)
					idb.createObjectStore(entity_name, { keyPath: "num" })
				})

				Object.values(IdbStoreKeys).forEach(name => {
					objectStoreNames.contains(name) && idb.deleteObjectStore(name)
					idb.createObjectStore(name)
					console.log(name)
				})

				resolve()
			}
			db_request.onsuccess = () => {
				idb = db_request.result
				resolve()
			}
			db_request.onerror = err => {
				console.error("db_request error", err)
			}
		})

		console.debug("[cache]: initialized IndexedDB")
	}

	let ctx = {
		entities,
		misc,
		resolve,
		update,
		updateBatch,
		drop,
		get idb() {
			return idb
		},
	}

	return Object.assign(<CacheContext.Provider value={ctx} children={props.children} />, { ctx })
}

let CacheContext = createContext<ReturnType<typeof CacheContextProvider>["ctx"]>()
export let useCache = () => useContext(CacheContext)
