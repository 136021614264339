/* @refresh reload */

import type { JSX, ParentProps, ResolvedJSXElement } from "solid-js"
import {
	batch,
	children,
	createComputed,
	createContext,
	For,
	mergeProps,
	onCleanup,
	// Suspense,
	untrack,
	useContext,
} from "solid-js"
import { createRwSignal, Ref } from "#/lib/utils"
import { animateStackIn, animateStackOut, highlight } from "./animations"
import type { MatchFilter, Params, PathMatch } from "./helpers"
import { createLocation, createMatcher, expandOptionals, hide, moveOnTop, setVisibility, show } from "./helpers"
import type { RouteDefinition } from "../routes"
import { useAppContext } from "#/contexts"
// import { Spinner } from "#/components/spinner"

export type View = {
	placeholder: Placeholder

	// View data
	params: Params
	pathname: string
	rpath: string

	props?: any

	next: View | null
	parent: View | null
	active?: true

	// Runtime objects
	element?: HTMLElement
}

type Placeholder = PlaceholderDefinition & {
	matcher: (path: string) => PathMatch[]
}

let getMatchers = (template: string, matchFilters?) =>
	expandOptionals(template).map(path => createMatcher(path, false, matchFilters))

let getPath = () => window.location.pathname + window.location.search + window.location.hash
let getState = () => history.state

function createAdvancedRouteContext() {
	let app = useAppContext()
	let rwPath = createRwSignal(getPath())
	let rwState = createRwSignal(getState())
	let rwTop = createRwSignal<View>(null)

	let rwPlaceholders = createRwSignal<Placeholder[]>([])

	let rwBlocked = createRwSignal(false)

	let ctx = {
		graph: [] as View[],
		subscribers: [] as (() => void)[],
		handlers: new Map<string, (el: Element) => void>(),

		// compare unwrapped values
		get top() {
			return rwTop()
		},
		set top(value) {
			rwTop(value)
		},

		get path() {
			return rwPath()
		},
		set path(value) {
			rwPath(value)
		},

		location: createLocation(rwPath, rwState),

		enqeue,
		switchTo,
		stack,
		unstack,
		onRoutesChanged,
		render,
		handleExternal,
		commit,

		rwPlaceholders,
		rwBlocked,

		view: undefined as View,
	}

	Object.assign(window, {
		navgraph: () => ctx.graph,
		navtop: top,
	})

	type UpdateBrowserHistoryOptions = {
		path?: string
		state?: any
		force?: "replace" | "push" | "back"
	}

	type RuntimeNavigationOptions = UpdateBrowserHistoryOptions & {
		props?: any
	}

	function commit(path: string): void
	function commit(options: UpdateBrowserHistoryOptions): void
	function commit(options: UpdateBrowserHistoryOptions | string): void {
		let {
			path = getPath(),
			force,
			state = history.state,
		}: UpdateBrowserHistoryOptions = typeof options === "string" ? { path: options } : options

		let method = force ?? (path === getPath() ? "replace" : "push")
		if (force === "back") {
			history.back()
		}
		else {
			history[`${method}State`](state, undefined, path)
		}
		batch(() => {
			rwPath(path)
			rwState(state)
		})
	}

	let queue = []
	function enqeue(op) {
		queue.push(op)
		if (!ctx.rwBlocked()) {
			perform()
		}
	}
	function perform() {
		queue.shift()?.()
	}
	createComputed(() => {
		if (!ctx.rwBlocked()) {
			untrack(perform)
		}
	})

	function handleExternal(preserve = false) {
		let current_path = getPath()
		let matches = getMatches(current_path)
		if (!matches.length) {
			console.warn("No routes found while performing external navigation", ctx.rwPlaceholders())
			return
		}
		// switchTo(matches[0][1].path) // matcher matches /* as / always

		let [, match] = matches[0] ?? []
		if (!match) {
			console.warn("handleExternal: destination is undefined")
			return
		}

		let { top } = ctx

		// exact match
		// this is not ideal but ok
		let view = ctx.graph.find(view => view.pathname === match.path)
		if (!view) {
			let [match_without_hash] = match.path.split("#")
			view = ctx.graph.find(view => view.pathname === match_without_hash)
		}

		if (top && view) {
			if (view.parent?.pathname === top.pathname) {
				stack(current_path)
				return
			}
			if (top.parent?.pathname === view.pathname) {
				unstack({ force_to: current_path, preserve: true })
				return
			}
		}
		switchTo({ path: current_path, preserve })
	}

	window.addEventListener("popstate", e => {
		if (ctx.rwBlocked()) {
			enqeue(() => handleExternal(true))
			return
		}
		handleExternal(true)
	})

	function notifyGraph() {
		ctx.subscribers.forEach(listener => listener())
	}

	function removeView(view: View) {
		if (ctx.top === view) {
			ctx.top = null
		}
		ctx.graph.spliceSafe(ctx.graph.indexOf(view), 1)
		notifyGraph()
		// logGraph(ctx.graph)
	}

	function addView(view: View) {
		ctx.graph.push(view)
		notifyGraph()
		// logGraph(ctx.graph)
	}

	function getMatches(pathname: string) {
		let placeholders_matches: [Placeholder, PathMatch][] = []
		for (let pl of ctx.rwPlaceholders()) {
			let matches = pl.matcher(pathname).filter(Boolean)

			if (matches.length) {
				placeholders_matches.push([pl, matches[0]])
			}
		}
		return placeholders_matches
	}

	async function navigate(opts: RuntimeNavigationOptions) {
		let url = new URL(opts.path, "http://faundr")

		let placeholders_matches: [Placeholder, PathMatch][] = getMatches(url.pathname)
		if (!placeholders_matches.length) {
			console.error("No matched placeholders for route", opts.path)
			return null
		}
		let previous_placeholders_matches: [Placeholder, PathMatch][] = getMatches(location.pathname)

		let [previous_placeholder, previous_match] = previous_placeholders_matches[0]
		let [placeholder, match] = placeholders_matches[0]

		let { top } = ctx
		if (top?.element && top.pathname === match.path) {
			if (previous_placeholder) {
				let previous_view = ctx.graph.find(v => v.pathname === previous_match.path)
				if (previous_view) {
					hide(previous_view)
				}
			}
			commit(opts)
			show(top)
			return top
		}

		let existing_view = ctx.graph.find(v => v.pathname === match.path)

		if (existing_view) {
			if (existing_view.element) {
				existing_view.props = opts.props

				ctx.top = existing_view

				app.meta.viewport["interactive-widget"] = existing_view.placeholder.route["interactive-widget"]

				commit(opts)
				return existing_view
			}
			ctx.graph.spliceSafe(ctx.graph.indexOf(existing_view), 1)
			notifyGraph()
		}

		if (ctx.graph.length > 25) {
			ctx.graph.pop() // TODO this can cause error if .next or .parent will be null
		}

		let view: View = {
			placeholder,

			pathname: match.path,
			params: match.params,

			rpath: opts.path,

			props: opts.props,

			parent: null,
			next: null,
		}

		let { promise: rendered, resolve } = Promise.withResolvers()
		ctx.handlers.set(view.pathname, resolve)
		addView(view)
		await rendered

		app.meta.viewport["interactive-widget"] = placeholder.route["interactive-widget"]

		commit(opts)
		ctx.handlers.delete(view.pathname)

		return view
	}

	async function stack(opts: RuntimeNavigationOptions): Promise<void>
	async function stack(to: string): Promise<void>
	async function stack(_opts: string | RuntimeNavigationOptions) {
		if (ctx.rwBlocked()) {
			return
		}
		ctx.rwBlocked(true)

		let opts: RuntimeNavigationOptions = typeof _opts === "string" ? { path: _opts } : _opts

		let departure = ctx.top

		// TODO: refactor
		let [_, match] = getMatches(opts.path)[0]

		let dest = ctx.graph.find(v => v.pathname === match.path)

		let circular = false
		if (departure && dest && departure?.parent === dest) {
			circular = true
			let _ = dest.parent
			departure.parent = _
			opts.force = "back"
		}

		dest = await navigate(opts)

		if (!dest) {
			console.warn("stack: destination is undefined")
			ctx.rwBlocked(false)
			return
		}

		if (departure === dest) {
			await highlight(departure.element)
			ctx.rwBlocked(false)
			return
		}

		if (!circular) {
			dest.parent = departure
		}

		let moveBack = moveOnTop(dest.element, departure.element)
		show(dest)
		let anim = app.isMobile() ? animateStackIn(departure.element, dest.element) : Promise.resolve()
		ctx.top = dest

		await anim
		hide(departure)
		moveBack()

		ctx.rwBlocked(false)
	}

	async function unstack(options?: { fallback?: string; preserve?: true; force_to?: string }) {
		if (ctx.rwBlocked()) {
			return
		}
		ctx.rwBlocked(true)

		let departure = ctx.top

		let to = options?.force_to ?? departure?.parent?.rpath ?? options?.fallback ?? "/deals"
		let dest = await navigate({ path: to })

		if (!dest) {
			console.warn("unstack: destination is undefined")
			ctx.rwBlocked(false)
			return
		}

		let moveBack = moveOnTop(departure.element, dest.element)

		show(dest)
		await (app.isMobile() ? animateStackOut(dest.element, departure.element) : Promise.resolve())
		hide(departure)

		ctx.top = dest

		moveBack()

		if (options?.preserve) {
			ctx.rwBlocked(false)
			return
		}

		removeView(departure)
		ctx.rwBlocked(false)
	}

	type SwitchToOptions = RuntimeNavigationOptions & NavigationOptions
	async function switchTo(to: string): Promise<void>
	async function switchTo(to: SwitchToOptions): Promise<void>
	async function switchTo(opts: string | SwitchToOptions) {
		if (ctx.rwBlocked()) {
			return
		}
		ctx.rwBlocked(true)

		opts = typeof opts === "string" ? { path: opts } : opts

		let dep = ctx.top
		let dest = await navigate(opts)
		if (!dest) {
			console.warn("switchTo: destination is undefined")
			ctx.rwBlocked(false)
			return
		}

		dep && hide(dep)
		ctx.top = dest
		show(dest)

		if (opts.preserve || (opts.preserve === undefined && dep?.placeholder.preserve === true)) {
			ctx.rwBlocked(false)
			return
		}

		if (dep && dep !== dest) {
			removeView(dep)
		}
		ctx.rwBlocked(false)
	}

	function onRoutesChanged(els: ResolvedJSXElement[]) {
		untrack(() => {
			ctx.rwPlaceholders(els as unknown as Placeholder[])
			console.log("Routes reloaded", ctx.rwPlaceholders())
			handleExternal(false)
		})
	}

	let Placeholder = (placeholder: Placeholder) => {
		let getViews = () => ctx.graph.filter(view => view.placeholder === placeholder)
		let sViews = createRwSignal(getViews(), { equals: false })
		let onGraphChanged = () => sViews(getViews())

		ctx.subscribers.push(onGraphChanged)
		onCleanup(() => {
			ctx.subscribers.spliceSafe(ctx.subscribers.indexOf(onGraphChanged), 1)
			for (let i = ctx.graph.length; i >= 0; i--) {
				if (ctx.graph[i]?.placeholder === placeholder) {
					ctx.graph.splice(i, 1)
				}
			}
		})

		return <For each={sViews()} children={View} />
	}

	function render() {
		return <For each={ctx.rwPlaceholders()} children={Placeholder} />
	}

	return ctx
}

function View(view: View) {
	let router = useRouter()

	function onRef(ref: Element) {
		if (ref instanceof HTMLElement) {
			view.element = ref
			setVisibility(ref, view.active)
			router.handlers.get(view.pathname)?.(ref)
			return
		}
		else if (ref) {
			console.error("Bad page provided", ref)
			return
		}
	}

	return (
		<RouterContext.Provider
			value={mergeProps(router, { view })}
			children={Ref({
				// TODO: hmr doesn't work ?
				// children: untrack(() => view.placeholder.component(view.props ?? {}, view.placeholder.preload?.())),
				children: <view.placeholder.component {...view.props} />,
				ref: onRef,
			})}
		/>
	)
}

let RouterContext = createContext<ReturnType<typeof createAdvancedRouteContext>>()
export let useRouter = () => useContext(RouterContext)

export function Router(props: ParentProps) {
	let ctx = createAdvancedRouteContext()
	return (
		<RouterContext.Provider
			value={ctx}
			children={[
				ctx.render(),
				props.children,
			]}
		/>
	)
}

Router.Routes = function(props: ParentProps) {
	let { onRoutesChanged } = useRouter()
	let c = children(() => props.children)
	// Run immideately
	createComputed(() => {
		let children = c.toArray().filter(Boolean)
		untrack(() => onRoutesChanged(children))
	})
	return <div></div>
}

type NavigationOptions = {
	preserve?: boolean
}

type PlaceholderDefinition<TPreload = any> = {
	route: RouteDefinition
	component<P = {}>(props: P, preload_promise?: Promise<TPreload>): JSX.Element
	match_filters?: Record<string, MatchFilter>
	preload?(): Promise<TPreload>
	// behaviour?: "stack" | "plain"
} & NavigationOptions

Router.RoutePlaceholder = function(props: PlaceholderDefinition) {
	let placeholder = {
		...props,
		matcher: (path: string) =>
			getMatchers(props.route.template, props.match_filters).map(matcher => matcher(path)),
	}
	return placeholder as unknown as JSX.Element
}

// https://developer.mozilla.org/en-US/docs/Web/API/Navigation_API#browser_compatibility
