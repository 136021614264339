import {
	type Component,
	type ComponentProps,
	createComputed,
	createSignal,
	type JSX,
	Show,
	splitProps,
} from "solid-js"
import { combineProps } from "#/lib/combine-props"
import type { ComponentLike } from "#/lib/utils"

export let IMG_REMOVE_BORDER_SRC =
	"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

export type PictureProps =
	& {
		src: string
		Img?: ComponentLike<"img">
		Fallback?: Component | JSX.Element
	}
	& ComponentProps<"img">

export function Picture(props: PictureProps) {
	props = combineProps({
		Img: (p) => <img use:imageviewer {...p} />,
		// src: IMG_REMOVE_BORDER_SRC,
	}, props)
	let [, other] = splitProps(props, ["src", "Fallback", "Img"])

	let [error, setError] = createSignal(false)

	createComputed(() => {
		props.src
		setError(false)
	})

	return (
		<Show
			when={!error() && props.src}
			fallback={typeof props.Fallback === "function" ? <props.Fallback /> : props.Fallback}
			children={

					<props.Img
						src={props.src}
						onLoad={[setError, false]}
						onError={[setError, true]}
						loading="lazy"
						{...other}
					/>


				// {...combineProps({ class: "max-w-inherit max-h-inherit" }, other)}
			}
		/>
	)
}
