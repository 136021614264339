import { createMemo, For, onMount, Show } from "solid-js"
import { useRouter } from "#/core/navigation/navigation"
import { ROUTES } from "#/core/routes"
import { useAuth } from "#/lib/auth"
import { langs } from "#/lib/i18n"
import { UserAvatar } from "../avatar"
import { Transition } from "solid-transition-group"
import { env, Platform } from "#/lib/behaviour"
import { combineProps } from "#/lib/combine-props"

export let MOBILE_NAVBAR_HEIGHT = "calc(3rem + env(safe-area-inset-bottom,0))"

export function MobileBottomNavbar() {
	let auth = useAuth()

	let isActive = (path: string) => router.top?.pathname === path
	let router = useRouter()

	let t = langs({
		ru: {
			[ROUTES.community.template]: "Сообщество",
			[ROUTES.projects.template]: "Проекты",
			[ROUTES.deals.template]: "Дела",
			[ROUTES.chats.template]: "Сообщения",
			[ROUTES.profile.resolve(auth.user.nickname)]: "Профиль",
		},
		en: {
			[ROUTES.community.template]: "Community",
			[ROUTES.projects.template]: "Projects",
			[ROUTES.deals.template]: "Deals",
			[ROUTES.chats.template]: "Messages",
			[ROUTES.profile.resolve(auth.user.nickname)]: "Profile",
		},
	})

	onMount(() => {
		if (env.rt.platform === Platform.IOS) {
			window.scrollTo(0, 0)
		}
	})

	return (
		<div
			class=":uno: fixed bottom-0 left-0 right-0 z-100 box-content max-w-inherit flex select-none justify-evenly p-bottom-safe dark:bg-gray-900 light:bg-white-000"
			style={{
				height: "3rem",
			}}
		>
			<For
				each={[
					[ROUTES.deals.template, props => <i i-hero:rectangle-stack {...props} />],
					[ROUTES.community.template, props => <i i-hero:user-group {...props} />],
					[ROUTES.projects.template, props => <i i-hero:squares-plus {...props} />],
					[ROUTES.chats.template, props => <i i-hero:chat-bubble-oval-left-ellipsis {...props} />],
					[
						ROUTES.profile.resolve(auth.user.nickname),
						props => (
							<UserAvatar
								{...combineProps({
									user: auth.user,
									classList: { ["outline-(2px solid blue-500)"]: props.active },
								}, props)}
							/>
						),
					],
				] as const}
			>
				{([path, Comp]) => {
					let active = createMemo(() => isActive(path))
					return (
						<div
							class=":uno: flex flex-basis-20% flex-col items-center justify-evenly"
							onClick={() => router.switchTo({ preserve: true, path })}
						>
							<Comp
								class=":uno: h7 w7 dark:c-gray-600 light:c-gray-300"
								active={active()}
								classList={{ ["c-blue-500!"]: active() }}
							/>
							<span
								class=":uno: text-2.5 font-500 dark:c-gray-600 light:c-gray-300"
								classList={{
									["c-blue-500!"]: active(),
								}}
								innerText={t()[path]}
							/>
						</div>
					)
				}}
			</For>
		</div>
	)
}

let MOBILE_NAVBAR_FORBIDDEN_ROUTES = [
	ROUTES.login,
	ROUTES.onboarding,
	ROUTES.chat,
	ROUTES.deal_edit,
	ROUTES.post,
	ROUTES.all,
] as const

export let useIsMobileNavbarAvailable = (view = () => useRouter().view) => {
	return createMemo(() => !MOBILE_NAVBAR_FORBIDDEN_ROUTES.includes(view()?.placeholder.route))
}

export function MobileNavbarPlaceholder() {
	let router = useRouter()
	let auth = useAuth()
	let isNavbarAvailable = useIsMobileNavbarAvailable(() => router.top)

	return (
		<Show when={auth.user && auth.is_authenticated}>
			<Transition
				enterActiveClass=":uno: transition-duration-250 transition-property-[bottom,opacity] transition-ease-[cubic-bezier(0.2,0.75,0.15,0.98)]"
				exitActiveClass=":uno: transition-duration-250 transition-property-[bottom,opacity] transition-ease-[cubic-bezier(0.2,0.75,0.15,0.98)]"
				enterClass="opacity-0 bottom--12!"
				exitToClass="opacity-0 bottom--12!"
			>
				<Show when={isNavbarAvailable()}>
					<MobileBottomNavbar />
				</Show>
			</Transition>
		</Show>
	)
}
