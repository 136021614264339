import type { User } from "@sferadel/ts-lib/entities"
import {
	batch,
	children,
	type Component,
	type ComponentProps,
	createComputed,
	type JSX,
	onCleanup,
	type Ref,
	untrack,
	type ValidComponent,
} from "solid-js"
import { createSignal, type Setter } from "solid-js"
import type { ReconcileOptions } from "solid-js/store"
import { modifyMutable, reconcile } from "solid-js/store"
import { env } from "./behaviour"
import { DEFAULT_DATE_LOCALE } from "@sferadel/ts-lib"

export let doNextFrame = (fn) => {
	if (env.rt.is_firefox) {
		return requestAnimationFrame(() => requestAnimationFrame(fn))
	}
	else {
		return requestAnimationFrame(fn)
	}
}

export function getPictureUrl(id: string) {
	if (id?.startsWith("http")) {
		return id
	}
	if (id?.at(0) === "/") {
		id = id.slice(1)
	}
	return id ? location.origin + "/s3/" + id : null
}

export function getUserAvatarImage(user: Pick<User, "avatar_key">) {
	return location.origin + "/s3/" + user?.avatar_key
}

export let onlyDev = <T, F = T>(value: T, fallback?: F) =>
	import.meta.env.DEV ? value : fallback ?? (value?.constructor?.() as T)

export function mergeMutableAdditive<T, U extends T>(
	target: T[],
	value: U[],
	options: ReconcileOptions = { merge: true },
) {
	let { key } = options
	batch(() => {
		for (let i = 0; i < value.length; i++) {
			let new_item = value[i]
			let existing_item_index = target.findIndex(item => item[key] != null && item[key] === new_item[key])
			if (existing_item_index < 0) {
				target.push(new_item)
			}
			else {
				modifyMutable(
					target[existing_item_index],
					// TODO make special recursive merger for each entitiy type
					reconcile({ ...target[existing_item_index], ...new_item }, options),
				)
			}
		}
	})
}

export function getImageDimension(src: string): Promise<{ width: number; height: number }> {
	return new Promise(resolve => {
		let img = new Image()
		img.onload = () => {
			resolve({
				height: img.naturalHeight,
				width: img.naturalWidth,
			})
			img.remove()
		}
		img.src = src
	})
}

export function Ref<T>(props: { ref: Ref<T | undefined>; children: T }): JSX.Element {
	let cb = props.ref as (el: T | undefined) => void

	let resolved = children(() => props.children)

	let prev: T | undefined

	createComputed(() => {
		let el = resolved.toArray().find(item => item instanceof Element) as T
		if (el !== prev) {
			untrack(() => cb(el))
		}
		prev = el
	})

	onCleanup(() => prev && cb(undefined))

	return resolved as unknown as JSX.Element
}

export function createRwSignal<T = any>(...args: Partial<Parameters<typeof createSignal<T>>>) {
	let [getter, setter] = createSignal<T>(...args)
	return <A extends Parameters<Setter<T>>>(...args: Partial<A>) => {
		// @ts-ignore
		if (args.length === 0) {
			return getter()
		}

		// @ts-ignore
		return setter(...args)
	}
}

export let isLocalImage = (url: string) => url?.startsWith("blob:")
export let blobToTypedArray = (blob: Blob) => blob.arrayBuffer().then(x => new Uint8Array(x))

// export let safeJsonReplacer = (key, value) =>
// 	typeof value === "bigint"
// 		? value.toString()
// 		: value

export type ComponentLike<T extends ValidComponent> = Component<ComponentProps<T>>

export let stopPropagation = (e: Event) => e.stopPropagation()

export function truncateText(
	text: string,
	{ max_chars = 200, max_words = 5, squeeze = true, ellipsis = false } = {},
) {
	if (squeeze) text = text.replace(/\n+/g, "\n")

	let cap_iof = -1
	for (let i = 0, len = text.length, wc = 0, last_word_len = 0; i < len; i++) {
		if (text[i] === " ") {
			if (i === len - 1) break

			while (text[i + 1] === " ") {
				i++
				continue
			}

			if (last_word_len > 3) {
				wc++
			}
			last_word_len = 0

			if (wc >= max_words || i >= max_chars) {
				cap_iof = i
				break
			}
		}
		else last_word_len++
	}

	if (ellipsis && cap_iof > -1) {
		text = text.slice(0, cap_iof) + "..."
		cap_iof += 3
	}

	return {
		text,
		truncated: cap_iof > -1,
	}
}

/**
```md
TODO: refactor to i18n module?
cardinal is number of items
ordinal is order of items
resolved categories/types could be debuggied via `plural_rule.resolvedOptions().pluralCategories`
ru-RU: one, few, many, other
```
*/
export let plurals = {
	cardinal_plural_rule: new Intl.PluralRules(DEFAULT_DATE_LOCALE, { type: "cardinal" }),
	ordinal_plural_rule: new Intl.PluralRules(DEFAULT_DATE_LOCALE, { type: "ordinal" }),
}

import { $PROXY } from "solid-js"
import type { Store } from "solid-js/store"
import { shapes } from "./shapes"
import toast from "solid-toast"

export function trackDeep<T extends Store<object>>(store: T): T {
	function traverse<T>(value: Store<T>, seen: Set<unknown>): void {
		let isArray: boolean
		let proto
		// check the same conditions as in `isWrappable` from `/packages/solid/store/src/store.ts`
		if (
			value != null && typeof value === "object" && !seen.has(value)
			&& ((isArray = Array.isArray(value))
				|| value[$PROXY]
				|| !(proto = Object.getPrototypeOf(value))
				|| proto === Object.prototype)
		) {
			seen.add(value)
			for (let child of isArray ? (value as any[]) : Object.values(value)) traverse(child, seen)
		}
	}

	traverse(store, new Set())
	return store
}

// export function getv(target: object, path: string, fallback: object = null) {
// 	let dot = typeof path === "string" ? path.indexOf(".") : -1

// 	if (dot === -1) {
// 		if (path.length) {
// 			if (path in target) {
// 				return target[path]
// 			}

// 			if (Array.isArray(target) && target.some((o) => Object.keys(o).includes(path))) {
// 				return target.map((item) => item[path])
// 			}
// 		}

// 		return fallback
// 	}

// 	return getv(
// 		Array.isArray(target) && !/^\d+$/.test(path.substring(0, dot))
// 			? target.map((item) => item[path.substring(0, dot)])
// 			: target[path.substring(0, dot)],
// 		path.substring(dot + 1),
// 		fallback,
// 	)
// }

export function mapData<
	S extends Record<string, any>,
	T extends Record<string, any>,
	KS extends keyof S = S extends { [key in infer KS]: any } ? KS : never,
	KT extends keyof T = T extends { [key in infer KT]: any } ? KT : never,
	KK extends (keyof S & keyof T) = KS & KT
>(
	state: S,
	target: T,
	keys: (KK | { [K in KS]: [K, Partial<S[K]>] }[KS])[],
	opts = {
		fallback_to_state: true,
	},
) {
	batch(() => {
		for (let entry of keys) {
			let [key, fallback] = Array.isArray(entry) ? entry : [entry, target[entry]] as const
			if (!opts.fallback_to_state) {
				// @ts-ignore
				state[key] = fallback
			}
			else {
				// @ts-ignore
				state[key] = fallback ?? state[key]
			}
		}
	})
}

export function errorHandled(item: any | Error, prefix?: string): item is Error {
	if (item instanceof Error) {
		toast.error(prefix + item.message)
		return true
	}
	return false
}

export let DeletedUser: shapes.MinUser = {
	num: -1,
	last_seen_at: 0,
	avatar_key: "deleted_user_avatar",
	visible: true,
	name: "Deleted User",
	nickname: "deleted",
}

export let UnknownUser: shapes.MinUser = {
	num: -10,
	last_seen_at: 0,
	avatar_key: "deleted_user_avatar",
	visible: true,
	name: "Unknown User",
	nickname: "unknwon",
}
