import { batch, children, type ComponentProps, createComputed, mergeProps, on, onCleanup } from "solid-js"
import { createMutable } from "solid-js/store"
import { type ComponentLike, doNextFrame } from "./utils"
import { combineProps } from "#/lib/combine-props"

function getSafePosition(element: HTMLElement, wish_left: string, wish_top: string) {
	let page_bounds = document.body.getBoundingClientRect()
	let modal_bounds = element.getBoundingClientRect()

	// page right end - width of modal - some shit - scrollbar
	// dprint-ignore
	let left =`clamp(0px, ${wish_left}, calc(${page_bounds.right}px - ${modal_bounds.width.toFixed(0)}px - env(safe-area-inset-right)) - 8px)`
	return { left, top: wish_top }
}

export let CommonModalWrapper = (props: ComponentProps<"div">) => (
	<div {...combineProps({ class: "fixed z102 min-w-max will-change-transform" }, props)} />
)

/*
	I wasn't able to make <Transition> to not create persistent dom node to mount in
	There is a way to have singletone modal root and mount into it, but what if we want multiple roots?
	Maybe there is also a way with Portal, but Portal itself creates unnescessary wrapper <div>
	So it requires to reimplemented part of Transition logic here, unfortunatelly.
	Maybe later I will able to generalize it to replace <Transition> with it too.
*/
export function createOverlay() {
	let state = createMutable({
		show: false,
		top: null as string,
		left: null as string,
	})

	let overlay: HTMLElement
	let element: HTMLElement

	function set(opts: Partial<typeof state>) {
		batch(() => Object.assign(state, opts))
	}

	function showInPlace(e: Event & { currentTarget: HTMLElement }) {
		let { left, top } = e.currentTarget.getBoundingClientRect()
		set({ show: true, left: left + "px", top: top + 25 + "px" })
	}

	function showAtCursor(e: MouseEvent & { currentTarget: HTMLElement }) {
		set({ show: true, left: e.clientX + "px", top: e.clientY + "px" })
	}

	let smol_class = "scale-85 opacity-65".split(" ")
	let anim_class = "transition-(60 ease-linear property-[transform,opacity])".split(" ")
	type ModalDisplayOptions = {
		Content: ComponentLike<"div">
		Background?: ComponentLike<"div">

		cancellable?: boolean
		safe_position?: boolean
		centered?: boolean
	}
	let VirtualRoot = (props: ModalDisplayOptions) => {
		props = mergeProps({
			Background: p => <div {...p} />,

			cancellable: true,
			safe_position: true,
			centered: false,
		}, props)

		let unmount = () => {
			if (overlay) document.body.removeChild(overlay)
			element = null
			overlay = null
		}
		onCleanup(unmount)

		createComputed(on(() => state.show, (show) => {
			if (show) {
				// @ts-ignore
				element = children(props.Content)(ctx) as HTMLElement

				overlay = (
					<props.Background
						class=":uno: fixed inset-0 z101 w-[var(--page-width)] contain-none transition-(60 ease-linear property-[background-color,backdrop-filter]) dark:bg-gray-800/10 light:bg-gray-000/10 before-(content-empty absolute wfull hfull backdrop-blur-3px)"
						style={{ "container-type": "inline-size", }}
						on:click={(e) => {
							if (e.currentTarget !== e.target) return // prevent firing when <Content> has non-delegated events
							if (props.cancellable) {
								state.show = false
							}
						}}
						children={element}
					/>
				) as HTMLElement

				element.style.visibility = "hidden"
				document.body.appendChild(overlay)
				if (props.centered) {
					element.style.left = "50%"
					element.style.top = "50%"
					element.style.transform = "translate(-50%, -50%)"
				}
				else if (props.safe_position) {
					Object.assign(element.style, getSafePosition(element, state.left, state.top))
				}
				element.classList.add(...smol_class)

				doNextFrame(() => {
					if (!overlay) return

					element.addEventListener("transitionend", () => element.classList.remove(...anim_class), {
						once: true,
					})

					element.classList.add(...anim_class)
					element.classList.remove(...smol_class)
					element.style.visibility = null
				})
			}
			else if (overlay) {
				element.classList.add(...anim_class)

				doNextFrame(() => {
					if (!overlay) return
					element.addEventListener("transitionend", unmount, { once: true })
					element.classList.add(...smol_class)
				})
			}
		}, { defer: true }))

		return null
	}

	let ctx = Object.assign(state, {
		VirtualRoot,
		set,
		showInPlace,
		showAtCursor,
		overlay: () => overlay,
		element: () => element,
	})
	return ctx
}
