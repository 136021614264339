import {
	type Component,
	createContext,
	createRenderEffect,
	type JSX,
	onCleanup,
	type ParentComponent,
	useContext,
} from "solid-js"
import { spread } from "solid-js/web"

export let MetaContext = createContext<MetaContextType>()

interface TagDescription {
	tag: string
	props: Record<string, unknown>
	setting?: { close?: boolean; escape?: boolean }
	name?: string
	ref?: Element
}

export interface MetaContextType {
	addTag(tag: TagDescription): number
	removeTag(tag: TagDescription, index: number): void
}

let cascading_tags = ["title", "meta"]

// https://html.spec.whatwg.org/multipage/semantics.html#the-title-element
let title_tag_properties: string[] = []

let meta_tag_properties: string[] =
	// https://html.spec.whatwg.org/multipage/semantics.html#the-meta-element
	["name", "http-equiv", "content", "charset", "media"]
		// additional properties
		.concat(["property"])

let getTagKey = (tag: TagDescription, properties: string[]) => {
	let tag_props = Object.fromEntries(
		Object.entries(tag.props)
			.filter(([k]) => properties.includes(k))
			.sort(),
	)

	if (Object.hasOwn(tag_props, "name") || Object.hasOwn(tag_props, "property")) {
		tag_props.name = tag_props.name || tag_props.property
		delete tag_props.property
	}

	return tag.tag + JSON.stringify(tag_props)
}

function initClientProvider() {
	let cascaded_tag_instances = new Map()

	function getElement(tag: TagDescription) {
		if (tag.ref) {
			return tag.ref
		}
		let el = document.createElement(tag.tag)
		return el
	}

	return {
		addTag(tag: TagDescription) {
			if (cascading_tags.indexOf(tag.tag) !== -1) {
				let properties = tag.tag === "title" ? title_tag_properties : meta_tag_properties
				let tag_key = getTagKey(tag, properties)

				//  only cascading tags need to be kept as singletons
				if (!cascaded_tag_instances.has(tag_key)) {
					cascaded_tag_instances.set(tag_key, [])
				}

				let instances = cascaded_tag_instances.get(tag_key)
				let index = instances.length

				instances = [...instances, tag]

				// track indices synchronously
				cascaded_tag_instances.set(tag_key, instances)

				let element = getElement(tag)
				tag.ref = element

				spread(element, tag.props)

				let last_visited = null
				for (var i = index - 1; i >= 0; i--) {
					if (instances[i] != null) {
						last_visited = instances[i]
						break
					}
				}

				if (element.parentNode != document.head) {
					document.head.appendChild(element)
				}
				if (last_visited && last_visited.ref && last_visited.ref.parentNode) {
					document.head!.removeChild(last_visited.ref)
				}

				return index
			}

			let element = getElement(tag)
			tag.ref = element

			spread(element, tag.props)

			if (element.parentNode != document.head) {
				document.head.appendChild(element)
			}

			return -1
		},
		removeTag(tag: TagDescription, index: number) {
			let properties = tag.tag === "title" ? title_tag_properties : meta_tag_properties
			let tag_key = getTagKey(tag, properties)

			if (tag.ref) {
				let t = cascaded_tag_instances.get(tag_key)
				if (t) {
					if (tag.ref.parentNode) {
						tag.ref.parentNode.removeChild(tag.ref)
						for (let i = index - 1; i >= 0; i--) {
							if (t[i] != null) {
								document.head.appendChild(t[i].ref)
							}
						}
					}

					t[index] = null
					cascaded_tag_instances.set(tag_key, t)
				}
				else {
					if (tag.ref.parentNode) {
						tag.ref.parentNode.removeChild(tag.ref)
					}
				}
			}
		},
	}
}
export let MetaProvider: ParentComponent = props => {
	let actions = initClientProvider()
	return <MetaContext.Provider value={actions!} children={props.children} />
}

let MetaTag = (
	tag: string,
	props: { [k: string]: any },
	setting?: { escape?: boolean; close?: boolean },
) => {
	useHead({
		tag,
		props,
		setting,
		get name() {
			return props.name || props.property
		},
	})

	return null
}

export function useHead(tagDesc: TagDescription) {
	let c = useContext(MetaContext)
	if (!c) throw new Error("<MetaProvider /> should be in the tree")

	createRenderEffect(() => {
		let index = c!.addTag(tagDesc)
		onCleanup(() => c!.removeTag(tagDesc, index))
	})
}

export let Title: Component<JSX.HTMLAttributes<HTMLTitleElement>> = props =>
	MetaTag("title", props, { escape: true, close: true })

export let Style: Component<JSX.StyleHTMLAttributes<HTMLStyleElement>> = props =>
	MetaTag("style", props, { close: true })

export let Meta: Component<JSX.MetaHTMLAttributes<HTMLMetaElement>> = props => MetaTag("meta", props)

export let Link: Component<JSX.LinkHTMLAttributes<HTMLLinkElement>> = props => MetaTag("link", props)

export let Base: Component<JSX.BaseHTMLAttributes<HTMLBaseElement>> = props => MetaTag("base", props)

export let Stylesheet: Component<Omit<JSX.LinkHTMLAttributes<HTMLLinkElement>, "rel">> = props =>
	<Link rel="stylesheet" {...props} />
