if (!window.queueMicrotask) {
	window.queueMicrotask = setTimeout
}

if (!Promise.withResolvers) {
	Promise.withResolvers = function() {
		let resolve, reject
		let promise = new Promise((res, rej) => {
			resolve = res
			reject = rej
		})
		return { promise, resolve, reject }
	}
}

if (!window.requestIdleCallback) {
	window.requestIdleCallback = setTimeout
	window.cancelIdleCallback = clearTimeout
}

// if (typeof BigInt.prototype.toJSON === "undefined") {
// 	BigInt.prototype.toJSON = function() {
// 		return this.toString()
// 	}
// }
