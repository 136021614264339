import { NOOP_ERR } from "@sferadel/ts-lib/index"

type FetchParams = Omit<RequestInit, "body"> & {
	qsParams?: Record<string, string | number | boolean>
	onUploadProgress?(progress: number): void
	modify?(params: FetchParams): void
	body?: any
}

// TODO: refactor this
function createApi() {
	let headers = {}
	async function fetcher<TResult>(url: string | URL, opts: FetchParams = {}): Promise<TResult | Error> {
		if (opts.body instanceof Blob && opts.onUploadProgress) {
			let bytes_uploaded = 0
			let bytes_count = opts.body.size
			let stream = new TransformStream({
				transform(chunk, controller) {
					controller.enqueue(chunk)
					bytes_uploaded += chunk.byteLength
					opts.onUploadProgress(bytes_uploaded / bytes_count)
				},
				flush(controller) {
					opts.onUploadProgress(1)
				},
			})
			opts.body = opts.body.stream().pipeThrough(stream)
		}
		else if (opts.onUploadProgress) {
			throw new Error("body should be blob for progress tracking!")
		}

		if (opts.headers) {
			Object.assign(opts.headers, headers)
		}
		else {
			opts.headers = structuredClone(headers)
		}

		opts.modify?.(opts)

		let response = await fetch(url, opts).catch(NOOP_ERR)

		if (response instanceof Error) {
			return new NetworkError().fromError(response, "No connection to server")
		}

		if (response.headers.get("content-length") === "0") {
			return response.ok ? null : new Error(response.statusText)
		}

		let obj = await response.json().catch(NOOP_ERR) as TResult | Error

		if (obj instanceof Error) {
			return new NetworkError().fromError(obj, "Malformed response from server")
		}

		if (!response.ok) {
			return obj ? errorFromObj(obj) : new NetworkError("Incorrect data from server")
		}
		return Object.getPrototypeOf(obj) === Object.prototype ? Object.setPrototypeOf(obj, null) : obj
	}

	// async function requestCbor<TResult = unknown>(url_str: string, opts: FetchParams = {}) {
	// 	if (opts.body?.constructor === Object) {
	// 		// let { encode } = await import("cbor-x")

	// 		opts.body = encode(opts.body)
	// 		opts.method ??= "POST"
	// 		opts.headers ??= {}
	// 		opts.headers["content-type"] = "application/cbor"
	// 	}

	// 	let url = new URL(url_str, location.origin)
	// 	if (opts.qsParams) {
	// 		// @ts-ignore
	// 		let params = new URLSearchParams(Object.entries(opts.qsParams).filter(([k, v]) => v !== undefined))
	// 		url.search = params.toString()
	// 	}

	// 	return await fetcher<TResult>(url, opts)
	// }

	function requestJson<TResult = unknown>(url_str: string, opts: FetchParams = {}) {
		if (opts.body?.constructor === Object) {
			opts.body = JSON.stringify(opts.body)
			opts.method ??= "POST"
			opts.headers ??= {}
			opts.headers["content-type"] = "application/json"
		}

		let url = new URL(url_str, location.origin)
		if (opts.qsParams) {
			// @ts-ignore
			let params = new URLSearchParams(Object.entries(opts.qsParams).filter(([k, v]) => v !== undefined))
			url.search = params.toString()
		}

		return fetcher<TResult>(url, opts)
	}

	return {
		req: requestJson,
		headers,
	}
}
export let api = createApi()
