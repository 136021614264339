export type * as entities from "./entities"

export let getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min
export let clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(value, max))

export type AppErrorType = "NETWORK" | "BAD_REQUEST" | "INTERNAL"

export function errorFromObj(obj: Partial<Error>) {
	if (!obj) {
		return null
	}
	let err = new Error()
	err.name = obj.name
	err.message = obj.message
	err.stack = obj.stack
	err.cause = obj.cause
	return err
}

export class DetailedError extends Error {
	original?: DetailedError | Error
	protected _message: string

	public get message() {
		return this._message + "\n" + this.original?.message
	}
	fromError(err: Error, message?: string) {
		this.original = err
		this._message = message
		return this
	}
}
export class NetworkError extends DetailedError {
	fromError(err: Error, message?: string) {
		this.original = err
		this._message = message
		return this
	}
}

export let DEFAULT_DATE_LOCALE = "ru-RU"
export let MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24
export let MIME_EXTENSION = {
	"image/webp": "webp",
	"image/x-icon": "ico",
	"image/x-ms-bmp": "bmp",
	"image/gif": "gif",
	"image/png": "png",
	"image/jpeg": "jpeg",
	"image/tiff": "tiff",
	"image/svg+xml": "svg",

	"audio/ogg": "ogg",
	"audio/mp4": "mp4",
	"audio/webm": "webm",

	"audio/mpeg": "mp3",
} as const

export let NOOP = _ => _
export let NOOP_ERR = (_: Error) => _

declare global {
	interface Array<T> {
		isEmpty(): boolean
		clear(): ReturnType<Array<T>["splice"]>
		spliceSafe(...params: Parameters<Array<T>["splice"]>): ReturnType<Array<T>["splice"]>
	}
}
Array.prototype.isEmpty = function isEmpty<T>(this: Array<T>) {
	return this.length === 0
}
Array.prototype.clear = function clear<T>(this: Array<T>) {
	return this.spliceSafe(0, this.length)
}
Array.prototype.spliceSafe = function safeSplice<T>(this: Array<T>, ...params: Parameters<Array<T>["splice"]>) {
	if (params[0] == -1) {
		return []
	}
	return this.splice(...params)
}

declare global {
	interface JSON {
		parseSafe<T>(json: string): T | null
	}
}
JSON.parseSafe = function parseSafe<T>(json: string) {
	let result: T | null = null
	try {
		result = JSON.parse(json)
	}
	catch {}
	return result
}

export let delay = (ms: number) => new Promise(res => setTimeout(res, ms))

export let POST_EMOJIS = ["👍", "🚀", "🤮", "😐", "🤔", "🤡", "😂"]
