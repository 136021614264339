let duration = 200
let easing = "cubic-bezier(0.2, 0.75, 0.15, 0.98)"
// let easing2 = "cubic-bezier(0.2, 0.75, 0.15, 0.98)"
let easing3 = "cubic-bezier(0.25, 1, 0.5, 1)"
let easing4 = "ease-in-out"

export function animateStackIn(back: HTMLElement, top: HTMLElement) {
	let backgroundAnimation = back.animate(
		[
			{ transform: `translate3d(0, 0, 0)`, filter: `brightness(100%)` },
			{ transform: `translate3d(-25vw, 0, 0)`, filter: `brightness(80%)` },
		],
		{ duration: duration, easing: easing4 },
	)
	let foregroundAnimation = top.animate(
		[{ transform: `translate3d(100vw, 0, 0)` }, { transform: `translate3d(0vw, 0, 0)` }],
		{ duration: duration, easing: easing4 },
	)
	return Promise.all([backgroundAnimation.finished, foregroundAnimation.finished])
}

export function animateStackOut(back: HTMLElement, top: HTMLElement) {
	let backgroundAnimation = back.animate(
		[
			{ transform: `translate3d(-25vw, 0, 0)`, filter: `brightness(80%)` },
			{ transform: `translate3d(0, 0, 0)`, filter: `brightness(100%)` },
		],
		{ duration: duration, easing: easing4 },
	)
	let foregroundAnimation = top.animate(
		[{ transform: `translate3d(0vw, 0, 0)` }, { transform: `translate3d(100vw, 0, 0)` }],
		{ duration: duration, easing: easing4 },
	)
	return Promise.all([backgroundAnimation.finished, foregroundAnimation.finished])
}

export function highlight(element: HTMLElement) {
	let animation = element.animate(
		[
			{
				filter: "invert(20%)",
			},
			{
				filter: "invert(0%)",
			},
		],
		{ duration: 320, easing: "linear" },
	)
	return animation.finished
}
