import type { Accessor } from "solid-js"
import { createMemo, getOwner, on, runWithOwner } from "solid-js"
import type { View } from "./navigation"

let IS_CONTENT_VISIBILITY_AVAILBLE = "contentVisibility" in document.body.style

export let VISIBILITY_PROPERTY = IS_CONTENT_VISIBILITY_AVAILBLE
	? "contentVisibility"
	: "visibility"

export function setVisibility(el: HTMLElement, visible: boolean) {
	if (!visible) {
		el.style.visibility = "hidden"
		IS_CONTENT_VISIBILITY_AVAILBLE && (el.style.contentVisibility = "hidden")
		el.ariaHidden = "true"
		el.style.pointerEvents = "none"
		return
	}
	el.style.visibility = null
	IS_CONTENT_VISIBILITY_AVAILBLE && (el.style.contentVisibility = null)
	el.ariaHidden = null
	el.style.pointerEvents = null
}

export function moveOnTop(foreground: HTMLElement, background: HTMLElement) {
	let fg_z = getComputedStyle(foreground).zIndex
	let bg_z = getComputedStyle(background).zIndex
	foreground.style.zIndex = String(Number(bg_z) || 0 + 10)

	return () => {
		foreground.style.zIndex = fg_z
	}
}

export function show(next: View) {
	next.active = true
	setVisibility(next.element, true)
}

export function hide(view: View) {
	delete view.active
	if (view.element) {
		setVisibility(view.element, false)
	}
}

export function logGraph(graph) {
	console.log(
		"graph",
		JSON.stringify(
			graph,
			(key, value) => {
				if (key === "parent") {
					if (value === null) {
						return null
					}
					let iof = graph.findIndex(x => x.path === value.path)
					return iof === -1 ? null : `element<${iof}>`
				}
				return value
			},
			2,
		),
	)
}

export function isBranchActive(node: View) {
	if (node.active) {
		return true
	}

	let next = node.next
	while (next) {
		if (next.active) {
			return true
		}
		next = next.next
	}
	let prev = node.parent
	while (prev) {
		if (prev.active) {
			return true
		}
		prev = prev.parent
	}
	return false
}

function extractSearchParams(url: URL): Params {
	let params: Params = {}
	url.searchParams.forEach((value, key) => {
		params[key] = value
	})
	return params
}

export type Params = Record<string, string>
export type MatchFilter = string[] | RegExp | ((s: string) => boolean)
type PathParams<P extends string | readonly string[]> = P extends `${infer Head}/${infer Tail}`
	? [...PathParams<Head>, ...PathParams<Tail>]
	: P extends `:${infer S}?` ? [S]
	: P extends `:${infer S}` ? [S]
	: P extends `*${infer S}` ? [S]
	: []

export interface PathMatch {
	params: Params
	path: string
}
type MatchFilters<P extends string | readonly string[] = any> = P extends string
	? { [K in PathParams<P>[number]]?: MatchFilter }
	: Record<string, MatchFilter>

export function createMatcher<S extends string>(path: S, partial?: boolean, matchFilters?: MatchFilters<S>) {
	let [pattern, splat] = path.split("/*", 2)
	let segments = pattern.split("/").filter(Boolean)
	let len = segments.length

	return (location: string): PathMatch | null => {
		let locSegments = location.split("/").filter(Boolean)
		let lenDiff = locSegments.length - len
		if (lenDiff < 0 || (lenDiff > 0 && splat === undefined && !partial)) {
			return null
		}

		let match: PathMatch = {
			path: len ? "" : "/",
			params: {},
		}

		let matchFilter = (s: string) =>
			matchFilters === undefined ? undefined : (matchFilters as Record<string, MatchFilter>)[s]

		for (let i = 0; i < len; i++) {
			let segment = segments[i]
			let locSegment = locSegments[i]
			let dynamic = segment[0] === ":"
			let key = dynamic ? segment.slice(1) : segment

			if (dynamic && matchSegment(locSegment, matchFilter(key))) {
				match.params[key] = locSegment
			}
			else if (dynamic || !matchSegment(locSegment, segment)) {
				return null
			}
			match.path += `/${locSegment}`
		}

		if (splat) {
			let remainder = lenDiff ? locSegments.slice(-lenDiff).join("/") : ""
			if (matchSegment(remainder, matchFilter(splat))) {
				match.params[splat] = remainder
			}
			else {
				return null
			}
		}

		return match
	}
}

function matchSegment(input: string, filter?: string | MatchFilter): boolean {
	let isEqual = (s: string) => s.localeCompare(input, undefined, { sensitivity: "base" }) === 0

	if (filter === undefined) {
		return true
	}
	else if (typeof filter === "string") {
		return isEqual(filter)
	}
	else if (typeof filter === "function") {
		return filter(input)
	}
	else if (Array.isArray(filter)) {
		return filter.some(isEqual)
	}
	else if (filter instanceof RegExp) {
		return filter.test(input)
	}
	return false
}

export function expandOptionals(pattern: string): string[] {
	let match = /(\/?\:[^\/]+)\?/.exec(pattern)
	if (!match) {
		return [pattern]
	}

	let prefix = pattern.slice(0, match.index)
	let suffix = pattern.slice(match.index + match[0].length)
	let prefixes: string[] = [prefix, prefix += match[1]]

	// This section handles adjacent optional params. We don't actually want all permuations since
	// that will lead to equivalent routes which have the same number of params. For example
	// `/:a?/:b?/:c`? only has the unique expansion: `/`, `/:a`, `/:a/:b`, `/:a/:b/:c` and we can
	// discard `/:b`, `/:c`, `/:b/:c` by building them up in order and not recursing. This also helps
	// ensure predictability where earlier params have precidence.
	while ((match = /^(\/\:[^\/]+)\?/.exec(suffix))) {
		prefixes.push(prefix += match[1])
		suffix = suffix.slice(match[0].length)
	}

	return expandOptionals(suffix).reduce<string[]>(
		(results, expansion) => [...results, ...prefixes.map(p => p + expansion)],
		[],
	)
}

export function createLocation(path: Accessor<string>, state: Accessor<any>) {
	let origin = new URL("http://faundr")
	let url = createMemo<URL>(
		prev => {
			let path_ = path()
			try {
				return new URL(path_, origin)
			}
			catch (err) {
				console.error(`Invalid path ${path_}`)
				return prev
			}
		},
		origin,
		{
			equals: (a, b) => a.href === b.href,
		},
	)

	let pathname = createMemo(() => url().pathname)
	let search = createMemo(() => url().search, true)
	let hash = createMemo(() => url().hash)
	let key = createMemo(() => "")

	return {
		get pathname() {
			return pathname()
		},
		get search() {
			return search()
		},
		get hash() {
			return hash()
		},
		get state() {
			return state()
		},
		get key() {
			return key()
		},
		query: createMemoObject(on(search, () => extractSearchParams(url())) as () => Params),
	} as const
}

export function createMemoObject<T extends Record<string | symbol, unknown>>(fn: () => T): T {
	let map = new Map()
	let owner = getOwner()!
	return new Proxy({} as T, {
		get(_, property) {
			if (!map.has(property)) {
				runWithOwner(owner, () =>
					map.set(
						property,
						createMemo(() => fn()[property]),
					))
			}
			return map.get(property)()
		},
		getOwnPropertyDescriptor() {
			return {
				enumerable: true,
				configurable: true,
			}
		},
		ownKeys() {
			return Reflect.ownKeys(fn())
		},
	})
}
