import "./theme.css"
import { Meta } from "#/lib/meta"
import type { ParentProps, Setter } from "solid-js"
import { createContext, createEffect, createSignal, useContext } from "solid-js"
import { AppCookieKey, AppCookies } from "../../lib/cookies"
import { doNextFrame } from "#/lib/utils"

export enum Theme {
	Light = "light",
	Dark = "dark",
}
function isSupportedTheme(theme: string): theme is Theme {
	return Object.values(Theme).includes(theme as Theme)
}

let ThemeContext = createContext({
	theme: () => Theme.Light as Theme,
	setTheme: ((theme: Theme) => void 0) as Setter<Theme>,
	transitTheme: (t0: Theme) => void 0,
})

let TRANSIT_DURATION_MS = 100
// let propsToTranist = ["background-color", "color", "fill", "stroke"]
// let style = propsToTranist.map(prop => `${prop} ${TRANSIT_DURATION_MS}ms ease`).join(",")
let SWITCH_THEME_CLASS = "switch-theme"

export function ThemeContextProvider(props: ParentProps) {
	let root = document.documentElement
	let preferDark = matchMedia("(prefers-color-scheme: dark)")

	let actualTheme: Theme | null = null
	let switchTimeout = Number.NEGATIVE_INFINITY

	let getRootBgColor = () => getComputedStyle(root).getPropertyValue("background-color")

	function getInitialTheme() {
		let theme = AppCookies.get(AppCookieKey.Theme) as Theme
		if (!isSupportedTheme(theme)) {
			theme = preferDark.matches ? Theme.Dark : Theme.Light
		}
		return theme
	}

	let [theme, setTheme] = createSignal(getInitialTheme())
	let [inTransit, setInTransit] = createSignal(false)

	applyTheme(theme())

	function transitTheme(to: Theme) {
		if (switchTimeout) {
			clearTimeout(switchTimeout)
		}
		setInTransit(true)
		root.classList.add("switch-theme")

		doNextFrame(() => {
			applyTheme(to)
			switchTimeout = window.setTimeout(() => {
				root.classList.remove("switch-theme")
				setTheme(to)
				setInTransit(false)
			}, TRANSIT_DURATION_MS)
		})
	}

	function applyTheme(theme: Theme) {
		if (actualTheme) {
			root.classList.replace(actualTheme, theme)
		}
		else {
			root.classList.add(theme)
		}
		if (actualTheme) {
			AppCookies.set(AppCookieKey.Theme, theme)
		}
		actualTheme = theme
	}

	preferDark.onchange = event => {
		let theme = event.matches ? Theme.Dark : Theme.Light
		transitTheme(theme)
		setTheme(theme)
	}

	createEffect(() => {
		if (inTransit() && !root.classList.contains(SWITCH_THEME_CLASS)) {
			root.classList.add(SWITCH_THEME_CLASS)
		}
		else {
			root.classList.remove(SWITCH_THEME_CLASS)
		}
	})
	return (
		<ThemeContext.Provider
			value={{ theme, setTheme, transitTheme }}
			children={[
				<Meta name="theme-color" content={getRootBgColor()} />,
				props.children,
			]}
		/>
	)
}

export let useThemeContext = () => useContext(ThemeContext)
