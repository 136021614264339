import { combineProps } from "#/lib/combine-props"

export function ItemIconWrapper(props) {
	return (
		<div
			{...combineProps(props, {
				class: ":uno: flex items-center justify-center min-w10 wfit relative overflow-visible [&>div:nth-child(1)]:(active:bg-gray-700/40 hover:bg-gray-700/40) cursor-pointer",
			})}
			children={[
				<div
					class="abs-centered inset-0 h10 w10 rounded"
					classList={{ [props.circle_cl]: !!props.circle_cl }}
				/>,
				props.children,
			]}
		/>
	)
}
