/* @refresh reload */
import "uno.css"
import "@unocss/reset/sanitize/forms.css"
import "#/assets/styles/index.css"
import "#/assets/styles/fonts.css"
import "./core/polyifills"
import "./core/globals"
import { MetaProvider, Title } from "#/lib/meta"
import { lazy, Match, Switch } from "solid-js"
import { render } from "solid-js/web"
import { Toaster } from "solid-toast"
import { MOBILE_NAVBAR_HEIGHT } from "#/components/navbar/mobile-navbar"
import {
	AppContextProvider,
	CacheContextProvider,
	DealsContextProvider,
	MessagesContextProvider,
	RpcContextProvider,
	useAppContext,
} from "./contexts"
import { Router } from "./core/navigation/navigation"
import { ThemeContextProvider } from "./core/theme/theme"
import { AuthContextProvider } from "./lib/auth"
import { I18n } from "./lib/i18n"
import { env } from "./lib/behaviour"

let MobileNavigator = lazy(() => import("./mobile.navigator"))
let DesktopNavigator = lazy(() => import("./desktop.navigator"))

if (typeof Android === "undefined") {
	window.Android = null
}

if ("serviceWorker" in navigator) {
	// let { default: sw_url } = await import("#/sw.ts?url") // vite places sw to assets and breaks it
	let sw_url = "/sw." + (import.meta.env.DEV ? "ts" : "js")
	navigator.serviceWorker.register(sw_url, { type: "module", scope: "/" })
}

render(App, document.body)

export function App() {
	console.log("environment", env)

	Android?.notify(`Привет`)
	Android?.showToast("lol")

	return [
		// if problem with cycling dependencies would appear, consider to use single context that will hold
		// event emitters / channels for each context. it should be called actor model idk
		<MetaProvider>
			<Title innerText={import.meta.env.DEV ? "[local] FAUNDR" : "FAUNDR"} />
			<I18n>
				<AppContextProvider>
					<ThemeContextProvider>
						<CacheContextProvider>
							<AuthContextProvider>
								<RpcContextProvider>
									<DealsContextProvider>
										<MessagesContextProvider>
											<Router>
												{function() {
													let app = useAppContext()
													return (
														<Switch>
															<Match when={/*@once*/ app.isMobile()}>
																<MobileNavigator />
															</Match>
															<Match when={/*@once*/ app.isDesktop()}>
																<DesktopNavigator />
															</Match>
														</Switch>
													)
												}()}
											</Router>
										</MessagesContextProvider>
									</DealsContextProvider>
								</RpcContextProvider>
							</AuthContextProvider>
						</CacheContextProvider>
					</ThemeContextProvider>
				</AppContextProvider>
			</I18n>
		</MetaProvider>,
		<Toaster
			toastOptions={{ position: "bottom-center", duration: 1550 }}
			containerStyle={{
				bottom: `calc(10px + ${MOBILE_NAVBAR_HEIGHT})`,
			}}
		/>,
	]
}
