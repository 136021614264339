import { useCache } from "#/contexts"
import { api } from "#/lib/api"
import { shapes } from "#/lib/shapes"
import { createContext, ParentProps, useContext } from "solid-js"
import { createMutable } from "solid-js/store"
import toast from "solid-toast"

export function DealsContextProvider(props: ParentProps) {
	let cache = useCache()

	let state = createMutable({
		loading: false,
	})

	let ctx = Object.assign(state, { pull })

	async function pull() {
		state.loading = true

		let response = await api.req<{
			deals: shapes.MinDeal[]
			users: shapes.MinUser[]
		}>("/api/deals")

		if (response instanceof Error) {
			state.loading = false

			toast.error("Error happened while fetching deals " + response.message)
			console.error(response)
			return
		}

		cache.updateBatch(response)
		cache.misc.addWatchedDeals(response.watched_deals)
		state.loading = false

		return response
	}

	return Object.assign(<DealsContext.Provider value={ctx} children={props.children} />, { ctx })
}

let DealsContext = createContext<ReturnType<typeof DealsContextProvider>["ctx"]>()
export let useDeals = () => useContext(DealsContext)
