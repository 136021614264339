import { COLORS } from "#/lib/colors"
import { AppCookieKey, AppCookies } from "#/lib/cookies"
import { Meta } from "#/lib/meta"
import { createEffect, onMount, ParentProps } from "solid-js"
import { createContext, createMemo, onCleanup, useContext } from "solid-js"
import { createMutable } from "solid-js/store"
import toast, { Toast } from "solid-toast"

let default_mobile_viewport_meta = {
	"width": "device-width",
	"height": "device-height",
	"initial-scale": "1.0",
	"user-scalable": "no",
	"viewport-fit": "cover" as "cover" | "contain", // https://stackoverflow.com/questions/66734725/pwa-disable-or-detect-the-black-bottom-bar-on-ios
	"interactive-widget": "overlays-content" as "resizes-visual" | "resizes-content" | "overlays-content", // don't resize viewport when keyboard is active
} as const

let default_desktop_viewport_meta = {
	"viewport-fit": "default_mobile_viewport_meta",
} as const

export enum ViewMode {
	Mobile = "phone",
	Desktop = "desktop",
}

function createAppContext() {
	let standalone_query = window.matchMedia("(display-mode: standalone)")
	let fs_query = window.matchMedia("(display-mode: fullscreen)")
	let is_mobile_query = window.matchMedia("(max-width: 1000px)")
	let change_view_mode_toast_id

	let store = createMutable({
		last_location: null as string,
		viewport: {
			mode: null as ViewMode,
			height: visualViewport.height,
			isFullscreen: () => standalone_query.matches || fs_query.matches,
			is_scaled: visualViewport.scale != 1,
			/**
			 * keyboard is opened?
			 *
			 * FIXME could be wrong
			 */
			is_shrinked: false,
		},
		meta: {
			viewport: {} as typeof default_mobile_viewport_meta,
		},
		setViewMode,
		isDesktop() {
			return this.viewport.mode === ViewMode.Desktop
		},
		isMobile() {
			return this.viewport.mode === ViewMode.Mobile
		},
	})

	onMount(() => {
		let mode = function() {
			let cookies_value = AppCookies.get(AppCookieKey.ViewMode) as ViewMode
			if ([ViewMode.Desktop, ViewMode.Mobile].includes(cookies_value)) {
				return cookies_value
			}
			return is_mobile_query.matches ? ViewMode.Mobile : ViewMode.Desktop
		}()
		store.viewport.mode = mode
		document.documentElement.style.setProperty("--bg-light", COLORS["white-000"])
		switch (mode) {
			case ViewMode.Mobile:
				document.documentElement.style.setProperty("--page-width", "min(100lvw, 420px)")
				store.meta.viewport = { ...default_mobile_viewport_meta }
				break
			case ViewMode.Desktop:
				document.documentElement.style.setProperty("--page-width", "100vw")
				// document.documentElement.style.setProperty("--bg-light", COLORS["gray-000"])
				store.meta.viewport = { ...default_desktop_viewport_meta }
				break
		}
	})

	// useBeforeLeave(e => {
	//    // console.log('BeforeLeave', e)
	//    store.lastLocation = e.from.pathname
	// })

	function onVvResize() {
		if (visualViewport.scale === 1) {
			// Guess if keyboard is open -_-
			store.viewport.is_shrinked = visualViewport.height < store.viewport.height
		}
		store.viewport.height = visualViewport.height
	}

	function setViewMode(mode: ViewMode) {
		AppCookies.set(AppCookieKey.ViewMode, mode)
		location.reload()
	}

	function onViewModeChanged(this: MediaQueryList, ev: MediaQueryListEvent) {
		console.log(onViewModeChanged)
		if (change_view_mode_toast_id) {
			// dprint-ignore
			try { toast.dismiss(change_view_mode_toast_id) } catch(e) {}
		}

		change_view_mode_toast_id = toast.custom(
			m => ChangeViewModeToast(m, ev.matches ? ViewMode.Mobile : ViewMode.Desktop),
			{ duration: Number.POSITIVE_INFINITY },
		)
	}

	visualViewport.addEventListener("resize", onVvResize)
	is_mobile_query.addEventListener("change", onViewModeChanged)
	onCleanup(() => {
		visualViewport.removeEventListener("resize", onVvResize)
		is_mobile_query.removeEventListener("change", onViewModeChanged)
	})

	return store
}

let AppContext = createContext<ReturnType<typeof createAppContext>>()
export function AppContextProvider(props: ParentProps) {
	let ctx = createAppContext()
	let viewport_content = createMemo(() =>
		Object.entries(ctx.meta.viewport).map(([key, value]) => `${key}=${value}`).join(", ")
	)

	return [
		<Meta name="viewport" content={viewport_content()} />,
		<AppContext.Provider value={ctx}>{props.children}</AppContext.Provider>,
	]
}
export let useAppContext = () => useContext(AppContext)

let ChangeViewModeToast = (t: Toast, next_mode: ViewMode) => (
	<div class=":uno: flex flex-col gap2 dark:bg-gray-700/90 light:bg-gray-100/90 backdrop-blur-2px p8px rounded-2 max-w-[var(--page-width)]">
		<div>
			Переключиться на {next_mode === ViewMode.Desktop
				? [<i class="i-hero:computer-desktop" />, "десктопную"]
				: [<i class="i-hero:device-phone-mobile" />, "мобильную"]} версию?
		</div>
		<div class="flex gap2">
			<div
				class="bg-gray-600 hover:bg-gray-400 p2 rounded-2 cursor-pointer"
				innerText={"Перезагрузить"}
				onClick={() => {
					AppCookies.remove(AppCookieKey.ViewMode)
					location.reload()
				}}
			/>
			<div
				class="hover:bg-gray-400 p2 rounded-2 cursor-pointer"
				innerText={"Позже"}
				onClick={[toast.remove, t.id]}
			/>
		</div>
	</div>
)
