import { delay, NetworkError } from "@sferadel/ts-lib/index"
import { EventEmitter } from "eventemitter3"
import { batch, createContext, createMemo, createSignal, type JSX, onCleanup, useContext } from "solid-js"
import { createMutable } from "solid-js/store"
import toast from "solid-toast"
import type { shapes } from "#/lib/shapes"
import { api } from "./api"
import { LocalStorageKeys } from "./storage-keys"
import { createRwSignal } from "./utils"
import { useCache } from "#/contexts"

export enum AuthState {
	Unknown = 1,
	Authenticating,
	Finished,
}

export function AuthContextProvider(props) {
	let cache = useCache()
	let store = createMutable({
		user_num: null as number | null,
		jwt: localStorage.getItem(LocalStorageKeys.JWT),
	})

	let [status, setStatus] = createSignal<AuthState>(AuthState.Unknown)

	let emitter = new EventEmitter<{ login: []; logout: [] }>()

	function tryAuthenticate(jwt: string) {
		if (status() === AuthState.Authenticating) {
			return
		}
		return authenticate(jwt)
	}

	async function authenticate(jwt: string) {
		setStatus(AuthState.Authenticating)
		store.jwt = jwt
		api.headers["authorization"] = `Bearer ${jwt}`

		let whoami = await async function() {
			let result: shapes.MeUser | Error
			for (let a = 1; a <= 4; a++) {
				result = await api.req<shapes.MeUser>("/api/whoami")

				if (result instanceof NetworkError) {
					let timeout_ms = 2 ** a * 800 + Math.random() * 200
					toast.error(() => (
						<ErrorCountdown
							from_ms={timeout_ms}
							Component={(props) => (
								<div
									children={[
										`Ошибка сети при аутентификации.\n`,
										`Подключение заново через ${props.countdown} с.\n`,
										`Попытка: ${a}.`,
									]}
								/>
							)}
						/>
					), {
						style: { background: "orange" },
						duration: timeout_ms - 100,
					})

					await delay(timeout_ms)
				}
				else {
					break
				}
			}
			return result
		}()

		if (whoami instanceof NetworkError) {
			toast.error("Не удалось подключиться к серверу, перезагрузите приложение")
			setStatus(AuthState.Unknown)
			return
		}

		if (whoami instanceof Error) {
			toast.error(whoami.message)
			setStatus(AuthState.Finished)
			logout()
			return
		}

		if (!whoami) {
			console.log("JWT is correct but is not valid")
			setStatus(AuthState.Finished)
			logout()
			return
		}

		batch(() => {
			store.user_num = whoami.num
			cache.update("users", [whoami])
			setStatus(AuthState.Finished)
		})

		localStorage.setItem(LocalStorageKeys.JWT, jwt)
		// if (env.can_use_telemetry) {
		// let { openReplay } = await import("./telemetry")
		// openReplay?.setUserID(whoami.nickname)
		// }

		emitter.emit("login")
	}

	async function logout() {
		// after changing user to getter from cache, it started to fail after logout
		// so I make sure components unmounted first and then we removing user.
		// Unfortunately, I'm not sure all timings in this Solid app.
		setStatus(AuthState.Unknown)

		batch(() => {
			let user_iof = cache.entities.users.findIndex(u => u.num === store.user_num)
			cache.entities.users.spliceSafe(user_iof, 1)
			store.user_num = null
			setStatus(AuthState.Finished)
		})

		delete api.headers["authorization"]
		if (localStorage.getItem(LocalStorageKeys.JWT)) {
			localStorage.removeItem(LocalStorageKeys.JWT)
			toast.success("You are not authenticated anymore")
		}
		emitter.emit("logout")
	}

	let isTokenExpired = (token: string) =>
		Date.now() >= JSON.parse(atob(token.split(".")[1]).toString()).exp * 1000

	if (store.jwt && !isTokenExpired(store.jwt) && status() === AuthState.Unknown) {
		authenticate(store.jwt)
	}
	else {
		setStatus(AuthState.Finished)
	}

	async function updateUser(query: Partial<shapes.MeUser>) {
		let result = await api.req<shapes.MeUser>("/api/user", {
			method: "PUT",
			body: query,
		})
		if (result instanceof Error) {
			toast.error(result.message)
			return result
		}
		store.user_num = result.num
		cache.update("users", [result])

		return result
	}

	async function refetchUser() {
		let result = await api.req<shapes.MeUser>("/api/whoami")

		if (result instanceof Error) {
			toast.error(result.message)
			return
		}
		store.user_num = result.num
		cache.update("users", [result])
		return result
	}

	emitter.on("logout", cache.drop)
	onCleanup(() => emitter.off("logout", cache.drop))

	let userMemo = createMemo(() => cache.resolve("users", store.user_num) as shapes.MeUser)
	let ctx = {
		tryAuthenticate,
		logout,
		refetchUser,
		updateUser,
		emitter,
		get jwt() {
			return store.jwt
		},
		get status() {
			return status()
		},
		get is_auth_pending() {
			return status() === AuthState.Unknown || status() === AuthState.Authenticating
		},
		get is_authenticated() {
			return status() === AuthState.Finished && store.user_num !== null
		},
		get user() {
			return userMemo()
		},
	}

	return Object.assign(<AuthContext.Provider value={ctx} children={props.children} />, { ctx })
}

let AuthContext = createContext<ReturnType<typeof AuthContextProvider>["ctx"]>()
export let useAuth = () => useContext(AuthContext)

type ErrorCountdownProps = {
	from_ms: number
	Component?(props: { countdown: number }): JSX.Element
}

export function ErrorCountdown(props: ErrorCountdownProps) {
	let seconds = Math.floor(props.from_ms / 1000)
	let sc = createRwSignal(seconds)

	let timeout: number
	function countdown() {
		timeout = window.setTimeout(() => {
			let current = sc() - 1
			if (current === 0) {
				clearTimeout(timeout)
				return
			}
			sc(current)
			countdown()
		}, 1000 - new Date().getMilliseconds())
	}

	countdown()

	onCleanup(() => clearTimeout(timeout))

	return <props.Component countdown={sc()} />
}
