export let COLORS = {
	"white-000": "#ffffff",

	"blue-000": "#ebf0fa",
	"blue-100": "#bbcdf0",
	"blue-200": "#d0e0ff",

	"blue-300": "#4c7af5",
	"blue-450": "#3a6cf2",
	"blue-500": "#245df5",
	"blue-501": "#386eff",
	"blue-700": "#626e87",
	"blue-800": "#1e2636",
	"blue-900": "#1e3053",

	"red-500": "#f5244a",
	"gray-000": "#f6f6f6",
	"gray-100": "#f0f0f2",
	"gray-200": "#cbced5",
	"gray-300": "#a0a3a6",
	"gray-400": "#657078",
	"gray-600": "#465057",
	"gray-700": "#3b3f49",
	"gray-800": "#24252c",
	"gray-900": "#17181a",

	"black-999": "#000000",
}
