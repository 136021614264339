// https://stackoverflow.com/a/58065241
// https://github.com/Ajaxy/telegram-tt/blob/master/src/util/windowEnvironment.ts

export enum Platform {
	IOS = "IOS",
	macOS = "macOS",
	Windows = "Windows",
	Android = "Android",
	Linux = "Linux",

	Unknown = "Unknown",
}

export let env = function() {
	let { userAgent: ua, platform: pl } = window.navigator

	let platform = function() {
		if (["iPhone", "iPad", "iPod"].includes(pl) || (pl === "MacIntel" && navigator.maxTouchPoints > 2)) {
			return Platform.IOS
		}

		if (["Macintosh", "MacIntel", "MacPPC", "Mac68K"].includes(pl)) {
			return Platform.macOS
		}

		if (["Win32", "Win64", "Windows", "WinCE"].includes(pl)) {
			return Platform.Windows
		}

		if (/Android/.test(ua)) return Platform.Android

		if (/Linux/.test(pl)) return Platform.Linux

		return Platform.Unknown
	}()

	return {
		version: globalThis.SFERADEL_VERSION,
		is_android_build: globalThis.SFERADEL_IS_ANDROID,

		rt: {
			platform,
			get is_mobile() {
				let mobile_regex =
					/iOS|iPhone OS|Android|BlackBerry|BB10|Series ?[64]0|J2ME|MIDP|opera mini|opera mobi|mobi.+Gecko|Windows Phone/i

				let { maxTouchPoints } = navigator

				return (maxTouchPoints === undefined || maxTouchPoints > 0) && ua.search(mobile_regex) != -1
			},

			is_firefox: ["firefox", "iceweasel", "icecat"].some(name => ua.toLowerCase().includes(name)),
			is_ya_browser: ua.includes("YaBrowser"),

			has_webshare: "share" in navigator,

			get can_use_telemetry() {
				let blacklist_ips = ["localhost", "192.168", "127.0"]
				return !import.meta.env.DEV && !blacklist_ips.some(hn => location.hostname.startsWith(hn))
			},
		},
	}
}()
